import React from 'react'
import enqueue from './_fetchQueue'

export type IProps = {
  country: string;
  dimension: string;
  from: number;
  affiliates: Set<string>
}


export default function useTopTen({
  country,
  dimension,
  from,
  affiliates
}: IProps) : {
    dimension: string
}[] {
  const [data, set_data] = React.useState([]);
  const url = new URL('https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rDVUH68/versions/10/execute/7200/query')
  url.searchParams.append('from', from.toString())
  url.searchParams.append('dimension', dimension)
  url.searchParams.append('countries', country || '-')
  url.searchParams.append('operators', '-') 
  url.searchParams.append('gateways', '-') 
  url.searchParams.append('affiliates', affiliates.size === 0 ? '-' : [...affiliates.values()].join(',')) 
  url.searchParams.append('services1', '-') 
  React.useEffect(() => {
    if(affiliates.size === 0 && dimension === 'pubid'){
      return;
    } else {
      set_data([])
      enqueue(() =>  fetch(url.href)
        .then(x => x.json())
        .then(x => set_data(x)));
    }
  }, [
    url.href
  ]);

  return data
}