import React from 'react'
import * as Rx from 'rxjs'
import {map, scan, combineLatest, skip} from 'rxjs/operators'
import * as d3 from 'd3'

export default function Live() {

  const liveSubjectRef = React.useRef<Rx.Subject<{ now: Date, sales_1h: number }>>(new Rx.Subject<{ now: Date, sales_1h: number }>())

  React.useEffect(() => {

    const liveSubject = liveSubjectRef.current

    function connect() {
      var ws = new WebSocket(`wss://${window.location.host}/ws`);
      ws.onopen = function () {
        // subscribe to some channels
        ws.send(JSON.stringify({
          //.... some message the I must send when I connect ....
        }));
      };

      ws.onmessage = function (e) {
        console.log('Message:', e.data);
        liveSubject.next(JSON.parse(e.data))
      };

      ws.onclose = function (e) {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        setTimeout(function () {
          connect();
        }, 1000);
      };

      ws.onerror = function (err) {
        console.error('Socket encountered error: ', err['message'], 'Closing socket');
        ws.close();
      };

      return ws

    }

    const ws = connect();
    return () => ws.close()
  }, [])

  const svgRef = React.createRef<SVGSVGElement>()

  React.useEffect(() => {
    const root = d3.select("svg"),
      width = +root.attr("viewBox").split(" ")[2],
      height = +root.attr("viewBox").split(" ")[3];

    const M = { T: 10, B: 35, L: 70, R: 10 }
    let index = 0

    const y = d3.scaleLinear()
      .range([height - M.T - M.B, 0])
    const x = d3.scaleLinear()
      .range([0, width - M.L - M.R])
    const xAxis = d3.axisBottom(x).tickFormat(d3.timeFormat("%H:%M:%S"))
    const yAxis = d3.axisLeft(y)
    const line = (d3.line() as any)
      .x(d => x(d.now))
      .y(d => y(d.value))

    root.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(${[M.L, height - M.B + 10]})`)
    root.append('g')
      .attr('class', 'y axis')
      .attr('transform', `translate(${[M.L - 10, M.T]})`)
    root.append('g')
      .attr('class', 'data')
      .attr('transform', `translate(${[M.L, M.T]})`)
      .append('path')
      .attr('stroke', 'steelblue')
      .attr('fill', 'none')


    const interval$ = Rx.interval(500)

    liveSubjectRef.current.pipe(
        map(x => ({ ...x, now: new Date(x.now), client_now: new Date() }))
      , combineLatest(interval$)
      , map(x => x[0])
      , scan((acc, a) => !acc || (acc as any).client_now != (a as any).client_now ? { ...a, now: !!acc && (acc as any).now.valueOf() > a.now.valueOf() ? acc.now : a.now } : { ...(acc as any), now: (acc as any).now.valueOf() + 500*0.9 }, null)
      // , skip(1)
      , scan((acc, a) => {
        if (acc.length > 600) {
          acc.shift()
        }
        acc.push({ now: a.now, value: a.sales_1h })
        return acc
      }, [])
    )
      .subscribe((data : any[]) => {
        // console.log(data)
        x.domain(d3.extent(data, x => x.now))
        y.domain(d3.extent(data, x => x.value))

        root.select('.x.axis').call(xAxis)
        root.select('.y.axis').call(yAxis.tickValues(y.domain()))
        root.select('.data path').datum(data).attr('d', line)
      })
  }, [])

  return <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden'}}><svg ref={svgRef} viewBox="0 0 960 500"></svg></div>
}