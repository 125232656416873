import { useState, useEffect } from 'react'

function getSize() {
  if(typeof window === "undefined") {
    return {
      innerHeight: NaN,
      innerWidth: NaN,
      outerHeight: NaN,
      outerWidth: NaN
    }
  }
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  };
}

export default function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  function handleResize() {
    setTimeout(() => {
      setWindowSize(getSize());
    }, 300);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener("deviceorientation", handleResize, true);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener("deviceorientation", handleResize);
    };
  }, []);


  return windowSize;
}