import React from 'react'
import enqueue from './_fetchQueue'

export type IProps = {
  country: string;
  from: number;
  gateway: null | string;
  affiliate: null | string;
  publisher: null | string;
  operator: null | string;
  service1: null | string;
}

export function useLandingPagesCR_WithFilters({
  country,
  from,
  gateway,
  affiliate,
  publisher,
  operator,
  service1
}: IProps) {
  const [data, set_data] = React.useState([]);
  React.useEffect(() => {
    set_data([])
    enqueue(() => fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rE3Smbz/versions/14/execute/7200/query?from=${from}&countries=${country}&gateways=${gateway || '-'}&operators=${operator || '-'}&affiliates=${affiliate || '-'}&publishers=${publisher || '-'}&service1=${service1 || '-'}`)
      .then(x => x.json())
      .then(x => set_data(x)));
  }, [
    country,
    from,
    gateway,
    affiliate,
    publisher,
    operator,
    service1
  ]);

  return data
}