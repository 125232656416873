import React from 'react';
import useWindowSize from './hooks/window-resize';
import { Plot, d3 } from './Plot';
import {useCountryRevenueCostECPA_Classic} from './hooks/useCountryRevenueCostECPA';
import * as R from 'ramda';
import Slider from './utils/Slider';
// import { withRouter } from 'react-router-dom';
// import * as H from 'history';
export function CountryTimeSeriesProfit({ from, cost_window, revenue_window, sales_from, sales_to, country, gateway, affiliate, operator }: {
  from: number;
  cost_window: number;
  revenue_window: number;
  sales_from: number;
  sales_to: number;
  country: string;
  gateway: null | string;
  affiliate: null | string;
  operator: null | string;
  // history: H.History
}) {
  const data = useCountryRevenueCostECPA_Classic({ country, from, cost_window, revenue_window, sales_from, sales_to, gateway, affiliate, operator })
  return <PlotCountryTimeSeriesProfit data={data} sales_from={sales_from} from={from} sales_to={sales_to} />
}

export function PlotCountryTimeSeriesProfit({ data, sales_from, sales_to, from }) {
  const size = useWindowSize();
  return (<div style={{ position: 'relative', background: 'white' }}>
    <div style={{ position: 'relative', top: 0, zIndex: 1, left: 10, width: 500, display: 'flex', alignItems: 'baseline', background: 'white' }}>
      <select style={{ marginRight: 10 }} onChange={(e) => {
        const url = new URL(document.location.href)
        url.searchParams.set('from', e.target.value)
        url.searchParams.set('sales-from', Math.max(+e.target.value, sales_from).toString())
        url.searchParams.set('sales-to', '0')
        // history.push(url.pathname + url.search)
        window.location.href = url.href
      }}>
        {
          R.range(-360 * 5, 0).filter(x => x < (-360 * 1) ? x % 90 === 0 : x % 30 === 0).map(i =>
            <option key={i} value={i} selected={i === from}>{- 1 * i / 30} Months ago</option>
          )
        }
      </select>
      <div style={{ width: 100, flexShrink: 1, flexGrow: 0, marginRight: 10 }}><Slider min={from} max={0} value={sales_from} onChange={value => {
        const url = new URL(document.location.href)
        console.log({ sales_to, sales_from, value })
        url.searchParams.set('sales-from', value.toString())
        url.searchParams.set('sales-to', Math.min(sales_to + (value - sales_from), 0).toString())
        window.location.href = url.href
      }} /></div>
      <div style={{ width: 100, flexShrink: 1, flexGrow: 0 }}><Slider min={0} max={Math.abs(sales_from)} value={sales_to - sales_from} onChange={value => {
        const url = new URL(document.location.href)
        url.searchParams.set('sales-to', (sales_from + value).toString())
        window.location.href = url.href
      }} /></div>
    </div>
    <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, top: 30, zIndex: 1 }}>
      <Plot data={[
        {
          type: "bar",
          yaxis: 'y1',
          x: data.map(x => x.day),
          y: data.map(x => x.avg_dmb_sales),
          name: 'Avg DMB Sales',
          marker: {
            color: '#e2e2e255'
          },
        },
        {
          type: "bar",
          yaxis: 'y1',
          x: data.map(x => x.day),
          y: data.map(x => x.avg_sales - (x.avg_dmb_sales || 0)),
          name: 'Avg Sales',
          marker: {
            color: '#e2e2e2aa'
          },
        },
        {
          x: data.map(x => x.day),
          y: data.map(x => x.overall_margin),
          yaxis: 'y2',
          xaxis: 'x',
          type: "scatter",
          mode: "lines+points",
          marker: { color: "#ff77b4" },
          name: 'Overall Margin'
        },
        {
          x: data.map(x => x.day),
          y: data.map(x => x.avg_sales === 0 ? NaN : x.avg_cost / x.avg_sales),
          yaxis: 'y3',
          xaxis: 'x',
          type: "scatter",
          mode: "lines+points",
          marker: { color: "#fbb4ae" },
          name: 'Avg eCPA',
          line: {
            dash: 'dashdot',
            width: 2
          }
        },
        {
          x: data.map(x => x.day),
          y: data.map(x => x.avg_dmb_sales === 0 ? NaN : x.avg_dmb_cost / x.avg_dmb_sales),
          yaxis: 'y3',
          xaxis: 'x',
          type: "scatter",
          mode: "lines+points",
          marker: { color: "#f0d400" },
          name: 'Avg DMB eCPA',
          line: {
            dash: 'dashdot',
            width: 2
          }
        },
      ]} layout={{
        barmode: 'stack',
        width: size.innerWidth,
        height: size.innerHeight - 30,
        showlegend: true,
        legend: { "orientation": "h" },
        margin: {
          t: 0, b: 0, l: 50, r: 20
        },
        xaxis: {
          domain: [0, 0.95]
        },
        yaxis: {
          title: 'Sales', anchor: 'x',
          hoverformat: ',.0f',
          tickformat: ',s',
          domain: [0, 1],
        },
        yaxis2: {
          title: 'USD',
          titlefont: { color: 'rgb(148, 103, 189)' },
          tickfont: { color: '#9467bd' },
          anchor: 'free',
          position: 0.95,
          overlaying: 'y',
          side: 'right',
          showgrid: false,
          hoverformat: ',.0f',
          tickformat: ',s',
          domain: [0, 1],
          rangemode: 'tozero',
          zeroline: true,
          zerolinecolor: '#999',
          zerolinewidth: 4,
          range: [d3.min([d3.min(data, e => e.overall_margin)]) * 1.05, d3.max([d3.max(data, e => e.overall_margin)]) * 1.05]
        },
        yaxis3: {
          title: '',
          titlefont: {
            color: '#fbb4ae'
          },
          tickfont: { color: '#fbb4ae' },
          anchor: 'free',
          overlaying: 'y',
          side: 'right',
          position: 1,
          showgrid: false,
          hoverformat: ',.2f',
          rangemode: 'tozero',
          zeroline: false,
          range: [0, d3.max(data, x => x.avg_sales === 0 ? NaN : x.avg_cost / x.avg_sales) * 1.05]
        }
      }} /></div></div>);
}