import React from 'react'
import { d3, Plotly } from './Plot';
import * as R from 'ramda'
import { ARPUCQECPAByDimensionItem, useARPUCQECPAByDimension_WithFilters} from './hooks/useARPUCQECPAByDimension';
import { Plot } from './PlotlyWithHooks';
import getCategoryColor from './utils/getCategoryColor';
import { loadingRender } from './utils/RDS';

var has_clicked = false;

export type ARPUn = 'week_1' | 'month_1' | 'month_2' | 'month_3';

export function ARPUCQECPAByDimensionScatter({ dimension, from, to, country, gateway, affiliate, publisher, operator, page, service1, arpu_n }: {
  from: number;
  to: number;
  country: string;
  dimension: string;
  gateway: null | string;
  affiliate: null | string;
  publisher: null | string;
  operator: null | string;
  page: null | string;
  service1: null | string;
  arpu_n: ARPUn
}) {
  return React.useMemo(() => 
    <ARPUCQECPAByDimensionScatterNoCache {...{ dimension, from, to, country, gateway, affiliate, publisher, operator, page, service1, arpu_n }} />
  , [dimension, from, to, country, gateway, affiliate, publisher, operator, page, service1, arpu_n ])
}

function ARPUCQECPAByDimensionScatterNoCache({ dimension, from, to, country, gateway, affiliate, publisher, operator, page, service1, arpu_n }: {
  from: number;
  to: number;
  country: string;
  dimension: string;
  gateway: null | string;
  affiliate: null | string;
  publisher: null | string;
  operator: null | string;
  page: null | string;
  service1: null | string;
  arpu_n: ARPUn
}) {
  const result = useARPUCQECPAByDimension_WithFilters({ country, dimension, from, to, gateway, affiliate, publisher, operator, page, service1 })
  return loadingRender(Render, result, {dimension, gateway, operator, affiliate, country, arpu_n })
}

function Render({data, dimension, gateway, operator, affiliate, country, arpu_n}:
  {data: ARPUCQECPAByDimensionItem[], dimension: string, gateway: string, operator: string, affiliate: string, country: string, arpu_n: ARPUn}) {
  const results : ARPUCQECPAByDimensionItem[] = R.pipe(
    R.sortBy(x => (x.sales || 0) * -1),
    R.take(10),
    R.filter((x: any) => x.sales > 10)
  )(data);

  // not used anymore: const color_scale_lens = R.lensProp('cq')
  const label_lens_view = R.pipe(
    R.view(R.lensProp('dimension')),
    dimension === 'affiliate_id' ? R.pipe(R.split(':'), R.head) : x => x);
  const x_axis_lens_view = R.view(R.lensProp(`${arpu_n}_arpu`));
  const y_axis_lens_view = R.view(R.lensProp(`${arpu_n}_ecpa`));
  const size_lens_view = R.view(R.lensProp(`${arpu_n}_sales`));
  const cq_lens_view = R.view(R.lensProp(`${arpu_n}_cq`));


  const max_sales = d3.max(results.map(size_lens_view));

  const mkUrl = (dimensionValue: string, path: string, search: string) => {
    const url = new URL(document.location.href);
    url.pathname = path;
    url.search = search;
    const params = [['gateway', gateway], ['operator', operator], ['affiliate', affiliate]].concat(
      dimension === 'affiliate_id' ? [['affiliate', dimensionValue.split(':')[1]]] : []
    ).concat(
      dimension === 'gateway' ? [['gateway', dimensionValue.split(':')[0]], ['operator', dimensionValue.split(':')[1]]] : []
    );
    params.forEach(([name, value]) => {
      if (!!value) {
        url.searchParams.set(name, value);
      }
    });
    return url;
  };

  const arpu_n_short_label = arpu_n.split('_')[0][0].toUpperCase() + arpu_n.split('_')[1][0]
  const arpu_n_long_label = arpu_n.split('_')[0].toUpperCase() + ' ' + arpu_n.split('_')[1]

  const traces = [{
    x: results.map(x_axis_lens_view),
    y: results.map(y_axis_lens_view),
    hoverinfo: 'text',
    text: results.map(x => `
<b>${label_lens_view(x)}</b><br>
Sales: ${d3.format(',')(x.sales)}<br>
Sales ${arpu_n_short_label}: ${d3.format(',')(size_lens_view(x))}<br>
eCPA: ${d3.format('0.2f')(y_axis_lens_view(x))} <br>
CQ: ${d3.format('%')(cq_lens_view(x))} <br>
ARPU ${arpu_n_short_label} AVG: ${d3.format('0.2f')(x_axis_lens_view(x))}<br>
${arpu_n === 'week_1' ? `ARPU W1 Median: ${d3.format('0.2f')(x.week_1_revenue_50)}<br>` : ``}
<a href="${mkUrl(x.dimension, `/arpu/${country}`, `window=7&from=-150`).href}">ARPU Trend</a>
  `
    ),
    hoverlabel: {
      bgcolor: results.map(x => {
        const val = x.dimension.indexOf(':') > -1
          ? x.dimension.split(':')[1]
          : x.dimension;
        return d3.hsl(getCategoryColor(dimension, val)).darker(0.2).toString();
      }),
    },
    type: 'scatter',
    mode: 'markers',
    line: {
      color: '#00000033'
    },
    marker: {
      size: results.map(x => 100 * size_lens_view(x) / max_sales),
      color: results.map(x => {
        const val = x.dimension.indexOf(':') > -1
          ? x.dimension.split(':')[1]
          : x.dimension;
        return d3.hsl(getCategoryColor(dimension, val)).darker(0.2).toString();
      }),

      // colorscale: [[0, "darkorange"], [0.5, "yellow"], [1, "lightblue"]],
      line: {
        color: results.map(x => d3.hsl(getCategoryColor(dimension, x.dimension.split(':')[1])).darker(0.2).toString()),
        width: 2
      },
      // colorbar: {
      //   tickformat: '%',
      //   thickness: 16,
      //   y: 0.5,
      //   ypad: 0,
      //   title: 'CQ',
      //   titleside: 'bottom',
      //   outlinewidth: 1,
      //   outlinecolor: 'black',
      //   tickfont: {
      //     size: 11
      //   }
      // }
    },
  }];

  const annotations = results.map(r => ({
    x: x_axis_lens_view(r),
    y: y_axis_lens_view(r),
    text: label_lens_view(r),
    yref: 'y',
    xref: 'x',
    // arrowhead: 5,
    // arrowsize: 1, 
    yanchor: 'top',
    xanchor: 'center',
    ax: 0,
    ay: 0,
    align: 'center',
    font: {
      family: 'Arial',
      size: 12,
      color: d3.hsl(getCategoryColor(dimension, r.dimension.indexOf(':') > -1 ? r.dimension.split(':')[1] : r.dimension)).darker(1.5).toString()
    },
  }));

  const layout = {
    // title: `${dimension[0].toUpperCase() + dimension.slice(1)} eCPA vs ARPU Week 1`,
    showlegend: false,
    xaxis: {
      title: `ARPU ${arpu_n_long_label}`
    },
    yaxis: {
      title: 'eCPA'
    },
    // width: '100%', height: '100%',
    margin: {
      l: 50,
      r: 20,
      b: 50,
      t: 20,
      pad: 4
    },
    hovermode: 'closest',
    annotations: annotations
  };

  const handler = React.useMemo(() => myPlot => {
    console.log('myPlot', myPlot);
    window['myPlot'] = myPlot;
    myPlot.on('plotly_click', function ({ points }) {
      has_clicked = true;
      console.log('data', results[points[0].pointIndex], { curveNumber: 0, pointNumber: points[0].pointNumber });
      Plotly.Fx.hover(myPlot, [
        { curveNumber: 0, pointNumber: points[0].pointNumber },
      ]);
    });

    myPlot.on('plotly_unhover', function ({ points }) {
      if (has_clicked) {
        Plotly.Fx.hover(myPlot, [
          { curveNumber: 0, pointNumber: points[0].pointNumber },
        ]);
      }
    });

    myPlot.on('plotly_doubleclick', function () {
      console.log('plotly_doubleclick');
      has_clicked = false;
    });
  }, [results]);

  return Plot(traces, layout, {
    responsive: true,
    autosize: true,
    showSendToCloud: !true,
    modeBarButtons: [[{
      name: 'resizer',
      icon: Plotly.Icons['camera-retro'],
      click: function () {
        console.log('click');
      }
    }], [
      'hoverClosestCartesian',
      'hoverCompareCartesian'
    ]]
  }, handler
  );
}

