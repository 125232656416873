import {fromPairs} from 'ramda'

const mkStats = (props : string[]) => (data: any[]) : {
  [key: string]: { count: number, sum: number, mean: number, vari: number }
} => data.reduce(
  (acc, a) => fromPairs(props.map(p => {
    const { count, sum, mean, vari } = acc[p]
    const x = a[p]
    const k = 1 / (count + 1)
    const mean_ = mean + k * (x - mean)
    const ssr_ = (count - 1) * vari + k * count * (x - mean) * (x - mean)
    return [p, { count: count + 1, sum: sum + x, mean: mean_, vari: ssr_ / Math.max(1, count) }]
  }))
  , fromPairs(props.map(p => [p, { count: 0, sum: 0, mean: 0, vari: 0 }]))
)

export default mkStats