import React from 'react'
import { mkLoadingE, mkLoadedE, RDSE } from '../utils/RDS';
import enqueue from './_fetchQueue'

export type IProps = {
  country: string;
  dimension: string;
  from: number;
  window: null | number;
  resolution: null | number;
  gateway: null | string;
  affiliate: null | string;
  operator: null | string;
}

export type IPropsWithFilters = IProps & {
  page: null | string;
  service1: null | string;
  publisher: null | string;
}

export function useSalesBreakdownStack_Classic({
  country,
  dimension,
  from,
  resolution,
  window,
  gateway,
  affiliate,
  operator
}: IProps) {
  const [data, set_data] = React.useState([]);
  React.useEffect(() => {
    fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rCeNZ9o/versions/4/execute/7200/query?from=${from}&dimension=${dimension}&country=${country}&gateway=${gateway || '-'}&operator=${operator || '-'}&affiliate=${affiliate || '-'}&resolution=${resolution || 7}&window=${window || 7}`)
      .then(x => x.json())
      .then(x => set_data(x));
  }, [
    country,
    dimension,
    from,
    resolution,
    window,
    gateway,
    affiliate,
    operator
  ]);

  return data
}

export function useSalesBreakdownStack_WithFilters({
  country,
  dimension,
  from,
  resolution,
  window,
  gateway,
  affiliate,
  publisher,
  operator,
  page,
  service1
}: IPropsWithFilters) {
  const [data, set_data] = React.useState(mkLoadingE<any[]>());
  React.useEffect(() => {
    set_data(mkLoadingE<any[]>())
    enqueue(() => fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rE1daIY/versions/14/execute/7200/query?from=${from}&dimension=${dimension}&countries=${country}&gateways=${gateway || '-'}&operators=${operator || '-'}&affiliates=${affiliate || '-'}&publishers=${publisher || '-'}&pages=${page || '-'}&resolution=${resolution || 7}&window=${window || 7}&service1=${service1 || '-'}`)
      .then(x => x.json())
      .then(x => set_data(mkLoadedE(x))));
  }, [
    country,
    dimension,
    from,
    resolution,
    window,
    gateway,
    affiliate,
    publisher,
    operator,
    page,
    service1
  ]);

  return data
}