import React from 'react'
import { mkLoadingE, mkLoadedE, RDSE } from '../utils/RDS';
import enqueue from './_fetchQueue'

export type IProps = {
  country: string;
  dimension: string;
  from: number;
  to: number;
  gateway: null | string;
  affiliate: null | string;
  operator: null | string;
}

export type IPropsWithFilters = IProps & {
  page: null | string;
  service1: null | string;
  publisher: null | string;
}

export function useARPUCQECPAByDimension_Classic({
  country,
  dimension,
  from,
  to,
  gateway,
  affiliate,
  operator
}: IProps) {
  const [data, set_data] = React.useState(mkLoadingE<any[]>());
  React.useEffect(() => {
    set_data(mkLoadingE<any>())
    fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rBRHNVB/versions/19/execute/7200/query?dimension=${dimension}&from=${from}&to=${to}&country=${country}&gateway=${gateway || '-'}&operator=${operator || '-'}&affiliate=${affiliate || '-'}`)
      .then(x => x.json())
      .then(x => set_data(mkLoadedE(x)));
  }, [country, dimension, from, to, gateway, affiliate, operator]);

  return data
}

export type ARPUCQECPAByDimensionItem  = {
  dimension: string;
  sales: number;
  firstbillings: number;
  cq: number;
  cost: number | null;
  ecpa: number | null;
  week_1_sales: number;
  week_1_ecpa: number | null;
  week_1_cq: number | null;
  week_1_revenue: number | null;
  week_1_arpu: number;
  week_1_revenue_25: number;
  week_1_revenue_50: number;
  week_1_revenue_75: number;
  week_1_revenue_100: number;
  week_1_revenue_max: number;
  week_1_arpu_stddev: number;
  month_1_sales: number | null;
  month_1_ecpa: number | null;
  month_1_cq: number | null;
  month_1_revenue: number | null;
  month_1_arpu: number | null;
  month_1_arpu_stddev: number | null;
  month_2_sales: number | null;
  month_2_ecpa: number | null;
  month_2_cq: number | null;
  month_2_revenue: number | null;
  month_2_arpu: number | null;
  month_2_arpu_stddev: number | null;
  month_3_sales: number | null;
  month_3_ecpa: number | null;
  month_3_cq: number | null;
  month_3_revenue: number | null;
  month_3_arpu: number | null;
  month_3_arpu_stddev: number | null;
}

export function useARPUCQECPAByDimension_WithFilters({
  country,
  dimension,
  from,
  to,
  gateway,
  affiliate,
  publisher,
  operator,
  page,
  service1
}: IPropsWithFilters): RDSE<ARPUCQECPAByDimensionItem[]> {
  const [data, set_data] = React.useState(mkLoadingE<any[]>());
  React.useEffect(() => {
    set_data(mkLoadingE())
    enqueue(() => fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rE1IKEV/versions/6/execute/7200/query?dimension=${dimension}&from=${from}&to=${to}&countries=${country}&gateways=${gateway || '-'}&operators=${operator || '-'}&affiliates=${affiliate || '-'}&publishers=${publisher || '-'}&pages=${page || '-'}&services1=${service1 || '-'}`)
      .then(x => x.json())
      .then(x => set_data(mkLoadedE(x))))
  }, [country, dimension, from, to, gateway, affiliate, publisher, operator, page, service1]);

  return data
}