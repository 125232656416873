import React from 'react';

export type RDS<T,E> = {
  status: 'Loading'
} | {
  status: 'Loaded',
  data: T
} | {
  status: 'Error',
  error: E
}

export type RDSE<T> = RDS<T, Error>

export function mkLoadingE<T>() : RDSE<T> {
  return {status: 'Loading'}
}

export function mkLoadedE<T>(data: T) : RDSE<T> {
  return {status: 'Loaded', data}
}

export function mkErrorE<T>(error: Error) : RDSE<T> {
  return {status: 'Error', error} 
}

export function loadingRender<P, T>(R: React.ComponentType<P & {data: T}>, result: RDSE<T>, props: P) {
  return result.status == 'Loading' ? <h1>Loading...</h1>
  : result.status == 'Error' ? <pre>{result.error.toString()}</pre>
  : <R {...{...props, data: result.data}} />
}
