import React from 'react';
import { d3 } from './Plot';
import { Plot } from './PlotlyWithHooks';
import * as R from 'ramda';
import { useRevenueBreakdownStack_WithFilters } from './hooks/useRevenueBreakdownStack';
import { useSalesBreakdownStack_WithFilters } from './hooks/useSalesBreakdownStack';
import getCategoryColor from './utils/getCategoryColor';
import { loadingRender } from './utils/RDS';


export type IProps = {
  dimension: string;
  from: number;
  window: number;
  affiliate: null | string;
  publisher: null | string;
  country: string;
  operator: null | string;
  gateway: null | string;
  resolution: null | number;
  mode: 'bar' | 'line';
  legend: Legend | null;
  page: null | string;
  service1: null | string;
}

export type Legend = {
  orientation: 'v' | 'h',
  showlegend?: boolean
}

export function RevenueBreakdownStack({ dimension, page, country, from, window, affiliate, publisher, operator, gateway, service1, resolution, mode, legend }: IProps) {
  return React.useMemo(() => <RevenueBreakdownStackNoCache {...{dimension, page, country, from, window, affiliate, publisher, operator, gateway, resolution, service1, mode, legend }} />, [dimension, page, country, from, window, affiliate, publisher, operator, gateway, resolution, service1, mode, legend])
}

function RevenueBreakdownStackNoCache({ dimension, page, country, from, window, affiliate, publisher, operator, gateway, service1, resolution, mode, legend }: IProps) {
  const result = useRevenueBreakdownStack_WithFilters({ dimension, page, country, from, window, affiliate, publisher, operator, gateway, resolution, service1 });
  return loadingRender(RenderRevenueBreakdownStack, result, { dimension, page, country, from, window, affiliate, publisher, operator, gateway, service1, resolution, mode, legend })
}

function RenderRevenueBreakdownStack({ dimension, page, country, from, window, affiliate, publisher, operator, gateway, service1, resolution, mode, legend, data }: IProps & { data: any[] }) {
  return <PlotRevenueOrSalesStack legend={legend} dimension={dimension} metric='revenue' mode={mode} data={data} />
}

export function SalesBreakdownStack({ dimension, country, from, window, affiliate, publisher, operator, gateway, page, service1, resolution, mode, legend }: IProps) {
  const result = useSalesBreakdownStack_WithFilters({ dimension, country, from, window, affiliate, publisher, operator, gateway, page, resolution, service1 });
  return loadingRender(RenderSalesBreakdownStack, result, { dimension, country, from, window, affiliate, publisher, operator, gateway, page, service1, resolution, mode, legend });
}

export function RenderSalesBreakdownStack({ dimension, country, from, window, affiliate, publisher, operator, gateway, page, service1, resolution, mode, legend, data }: IProps & { data: any[] }) {
  return <PlotRevenueOrSalesStack legend={legend} dimension={dimension} metric='sales' mode={mode} data={data} />
}


export function PlotRevenueOrSalesStack({ dimension, metric, mode, data, legend }: { dimension: string, metric: 'revenue' | 'sales', mode: 'bar' | 'line', data: any[], legend: Legend }) {
  const color_dimension = dimension === 'operator_code' || dimension === 'gateway' ? 'operator_or_gateway' : dimension
  const stacks = R.pipe(
    R.groupBy((x: any) => x.dimension)
    , R.toPairs
    , R.map(([dimensionName, data]) => ({
      stackgroup: 'one',
      total: R.sum(data.map(x => x[metric])),
      x: data.map(x => x.d_row),
      y: data.map(x => Math.ceil(x[metric])),
      fillcolor: getCategoryColor(color_dimension, data[0].dimension),
      line: {
        color: d3.hsl(getCategoryColor(color_dimension, data[0].dimension)).darker(0.5).toString(),
      },
      // text: dimension,
      // hoverinfo: 'text',
      name: dimensionName,
      ...(mode === 'bar' ? { type: 'bar' } : {})
    }))
    , R.sortBy(x => x.total * -1)
  )(data)

  const layout = {
    showlegend: !(legend.showlegend === false),
    legend: { ...legend },
    margin: {
      t: 0, b: 20, l: 50, r: 20
    },
    xaxis: {
      domain: [0, 1]
    },
    yaxis: {
      title: metric === 'revenue' ? 'Revenue' : 'Sales', anchor: 'x',
      hoverformat: ',.0f',
      tickformat: ',s',
      domain: [0, 1],
    },
    ...(mode === 'bar' ? {
      bargap: 0,
      barmode: 'stack'
    } : {}),
    autosize: true,
  }

  const handler = React.useMemo(() => myPlot => {
    myPlot.on('plotly_legenddoubleclick', ({ data, curveNumber }) => {
      console.log('plotly_legenddoubleclick', data, data[curveNumber])
    })

    myPlot.on('plotly_legendclick', d => {
      console.log('plotly_legendclick', d)
    })

    myPlot.on('plotly_restyle', d => {
      console.log('plotly_restyle', d);
    })
  }, [])

  return Plot(stacks, layout, {
    responsive: true,
    autosize: true,
    showSendToCloud: !true
  }, handler)


}