import React from 'react';
import { d3 } from './Plot';
import "./DimensionSummaryTable.scss"
import { useDimensionSummaryTable_WithFilters } from './hooks/useDimensionSummaryTable';

export function DimensionTableSummary({ dimension, page, from, country, gateway, affiliate, publisher, operator, service1 }: {
  from: number;
  country: string;
  gateway: null | string;
  affiliate: null | string;
  publisher: null | string;
  operator: null | string;
  dimension: string;
  page: null | string;
  service1: null | string;
}) {
  const data = useDimensionSummaryTable_WithFilters({ dimension, page, country, from, gateway, affiliate, publisher, operator, service1 })
  return <PlotLandingPagesCR data={data} />
}

export function PlotLandingPagesCR({ data }) {
  return (<div className='data-table-container'>
    <table className='data-table'>
      <thead>
        <tr>
          <th style={{ width: '25%' }}></th>
          <th>Views</th>
          <th>Sales</th>
          <th>CR</th>
          <th>CQ</th>
          <th>Pixels</th>
          <th>eCPA</th>
          <th>W1</th>
        </tr>
      </thead>
      <tbody>
        {data.map(r => {
          return <tr key={`${r.dimension}`}>
            <td className='label'>{r.dimension}</td>
            <td>{d3.format(',')(r.views)}</td>
            <td>{d3.format(',')(r.sales)}</td>
            <td>{d3.format('0.2%')(r.cr)}</td>
            <td>{d3.format('%')(r.cq)}</td>
            <td>{d3.format('%')(r.pixels / r.sales)}</td>
            <td>{d3.format('0.2f')(r.ecpa)}</td>
            <td>{d3.format('0.2f')(r.week_1_arpu_by_avg)}</td>
          </tr>
        })}
      </tbody>
    </table>
  </div>);
}