import * as R from 'ramda'

const movingAverage = (size: number, arr: any[]) => arr.reduce((acc, a) => {
  let currentWindow = acc.currentWindow.concat([a])
  currentWindow = currentWindow.length > size
    ? R.drop(1, currentWindow)
    : currentWindow
  return {
    currentWindow,
    result: currentWindow.length === size
      ? acc.result.concat([R.sum(currentWindow) / size])
      : acc.result.concat([R.sum(currentWindow) / currentWindow.length])
  }
}, { currentWindow: [], result: [] })
.result

export default movingAverage