import React from 'react'

export type IProps = {
  dimension: string;
  country: string;
  from: number;
  window: number;
  affiliate: null | string;
}

export default function useEmpelloTicketsCountryStack({
  dimension,
  country,
  from,
  window,
  affiliate
} : IProps) {
  const [data, set_data] = React.useState([]);
  React.useEffect(() => {
    fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rvHR8xl/versions/21/execute/7200/query?dimension=${dimension}&from=${from}&affiliate=${affiliate || '-'}&country=${country || '-'}&window=${window}`)
      .then(x => x.json())
      .then(x => set_data(x));
  }, [
      dimension,
      country,
      from,
      window,
      affiliate
  ]);

  return data
}