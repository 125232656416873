import React from 'react';
import { d3 } from './Plot';
import { Plot, Plotly } from './PlotlyWithHooks';
import * as R from 'ramda'
import { useGrossMargin_WithFilters } from './hooks/useGrossMargin';
import movingAverage from './utils/movingAverage';
import * as d35t from 'd3-time-format'
import * as d35sc from 'd3-scale-chromatic'
import * as d35 from 'd3'
import * as d35a from "d3-array";
//
import { Checkbox, Slider, MenuItem } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import 'normalize.css/normalize.css'
import useTopTen from './hooks/useTopTen';
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";

type GrossMarginProps = {
  country: string;
  from: number;
  arpu_window: number;
  gateway: null | string;
  affiliate: null | string;
  operator: null | string;
  service1: null | string;
  page: null | string;
  as_if: boolean;
  months_after_sale: number;
}

export function GrossMargin({ country, from, gateway, affiliate, operator, service1, page, as_if, arpu_window, months_after_sale }: GrossMarginProps) {
  const date_from = (() => {
    const pad = d => d < 10 ? `0${d}` : `${d}`
    const d = new Date(new Date().valueOf() + (from - 1) * 24 * 3600 * 1000)
    return `${d.getFullYear()}-${pad(d.getMonth())}-01`
  })()
  const date_to = new Date().toJSON().split('T')[0]
  const resolution = 'month'
  const raw_data = useGrossMargin_WithFilters({ country, date_from, date_to, resolution, gateway, affiliate, operator, page, service1 })

  const arpu_cohorts_colors = d3.scale.category10().range(d35sc.schemeSet2)

  const data = R.pipe(
    x => x
    , R.groupBy(x => x.quarter)
    , R.map(data => ({
      key: data[0].quarter,
      values: R.map(x =>
        [new Date(x.day),
        as_if
          ? x.sum_gross_margin_for_quarter_cohort
          : x.sum_gross_margin]
      )(data.filter(x => new Date(x.day).valueOf() <
        new Date(data[0].quarter).valueOf() + months_after_sale * 30.5 * 24 * 3600 * 1000)
      )
    }))
    , R.toPairs
    , R.sortBy(x => x[0])
    , R.map(x => x[1])
    , xs => R.addIndex(R.map)(({ key, values }, i) => ({
        x: values.map(x => x[0]),
        y: values.map(x => Math.round(x[1])),
        mode: 'lines',
        key: key,
        name: d35t.timeFormat("%b %Y")(new Date(key)),
        yaxis: 'y2',
        visible: i === (xs.length - 1) || (i % Math.ceil(xs.length / 5)) === 0 ? true : 'legendonly',
        marker: {
          color: arpu_cohorts_colors(key)
        }
    }))(xs)
  )(raw_data)

  const sales_data = R.pipe(
    R.groupBy(x => x.day)
    , R.map(xs => xs[0].sales)
    , obj => ({
      x: R.keys(obj).map(x => new Date(x)),
      y: movingAverage(7, R.values(obj)),
      type: 'bar',
      name: 'Sales',
      marker: {
        color: '#e2e2e2aa'
      },
    })
  )(raw_data)

  const revenue_data = R.pipe(
    // R.filter(x => resolution === 'quarter' || new Date(x.quarter).getMonth() % (quarterly_or_biannular === 'quarterly' ? 3 : 6) === 0)
    x => x
    , R.groupBy(x => x.quarter)
    , R.map(data => ({
      key: data[0].quarter,
      values: R.map(x =>
        [new Date(x.day),
        as_if
          ? x.sum_revenue_for_quarter_cohort
          : x.sum_revenue]
      )(data.filter(x => new Date(x.day).valueOf() <
        new Date(data[0].quarter).valueOf() + months_after_sale * 30.5 * 24 * 3600 * 1000)
      )
    }))
    , R.toPairs
    , R.sortBy(x => x[0])
    , R.map(x => x[1])
    , xs => R.addIndex(R.map)(({ key, values }, i) => ({
        x: values.map(x => x[0]),
        y: values.map(x => x[1]),
        mode: 'lines',
        key: key,
        name: d35.timeFormat("%b %Y")(new Date(key)),
        yaxis: 'y4',
        visible: i === (xs.length - 1) || (i % Math.ceil(xs.length / 5)) === 0 ? true : 'legendonly',
        marker: {
          color: arpu_cohorts_colors(key)
        },
        showlegend: false,
    }))(xs)
  )(raw_data)

  const quarterly_sales_data = R.pipe(
    R.groupBy(x => x.day)
    , R.map(R.pipe(R.map(x => x.sum_sales_for_quarter_cohort), R.last))
    , obj => ({
      x: R.keys(obj).map(x => new Date(x)),
      y: R.values(obj),
      type: 'bar',
      name: 'Sales',
      marker: {
        color: '#e2e2e2aa'
      },
      yaxis: 'y5'
    })
  )(raw_data)

  const cohort_annotations =
    data.map(trace => ({
      xy: R.pipe(
        R.zip(trace.x),
        R.filter(x => x[1] === 0 || !!x[1]),
        // R.head,
        ds => (d35a as any).least(ds, (a, b) => d35.ascending(a[1], b[1]))
      )(trace.y),
      // xy: trace.xy,
      label: trace.name,
      key: trace.key,
      color: trace.marker.color,
      yref: 'y2',
      yanchor: 'top',
      xanchor: 'left',
      // xshift: 40 - Math.random() * 20,
      showarrow: !true,
      // ax: 25 - Math.random() * 50,
      visible: trace.visible === true,
      annotation_key: trace.key
    })
    )

  const breakeven_annotations = data
    .map(trace => {
      const xy = R.pipe(
        R.zip(trace.x),
        R.filter(x => x[1] === 0 || !!x[1]),
        R.reduce(({ before, result }, a) =>
          a[1] > 0 && before < 0
            ? { before: a[1], result: a }
            : { before: a[1], result }
          , { before: 0, result: null }
        ),
        x => x.result
      )(trace.y)
      return {
        xy: xy || [trace.x[0], 0],
        label: !!xy ? `${Math.round((xy[0] - trace.x[0].valueOf()) / (24 * 3600 * 1000))} days` : '',
        color: trace.marker.color,
        yref: 'y2',
        ay: Math.random() * -80,
        yanchor: 'bottom',
        xanchor: 'middle',
        visible: !xy ? false : trace.visible === true,
        showarrow: !xy ? false : true,
        annotation_key: trace.key
      }
    }
    )
    .filter(a => a.xy != null)

  const arpu_N_data = (n, color) => R.pipe(
    R.groupBy(x => x.day)
    , R.map(xs => xs[0][`revenue_${n}`] / xs[0][`sales_${n}`])
    , obj => ({
      x: R.keys(obj).map(x => new Date(x)),
      y: movingAverage(arpu_window, R.values(obj)),
      type: 'line',
      name: 'ARPU ' + n,
      marker: {
        color
      },
      yaxis: 'y3',
    })
  )(raw_data)

  const revenue_annotations = 
    revenue_data.map(trace => ({
      xy: R.pipe(
        R.zip(trace.x),
        R.filter(x => x[1] === 0 || !!x[1]),
        // R.head,
        ds => ds.length > 45 ? ds[56] : R.last(ds) // d35.least(ds, (a, b) => d35.ascending(a[1], b[1]))
      )(trace.y),
      // xy: trace.xy,
      label: trace.name,
      key: trace.key,
      color: trace.marker.color,
      yref: 'y4',
      yanchor: 'top',
      xanchor: 'left',
      // xshift: -7,
      showarrow: !true,
      // ax: 25 - Math.random() * 50,
      visible: trace.visible === true,
      annotation_key: trace.key
    })
    )
  const arpus_data = [[7, '#1feeb4b4'], [30, '#1D9A6C44'], [60, '#1D9A6C44'], [90, '#13717744']].map(([n, color]) => arpu_N_data(n, color))
  const ecpa_data = R.pipe(
    R.groupBy(x => x.day)
    , R.map(xs => xs[0].cost / xs[0].sales)
    , obj => ({
      x: R.keys(obj).map(x => new Date(x)),
      y: movingAverage(arpu_window, R.values(obj)),
      type: 'line',
      name: 'eCPA',
      marker: {
        color: '#FF3B6888'
      },
      line: {
        dash: 'dash'
      },
      yaxis: 'y3',
    })
  )(raw_data)

  const last = trace => R.pipe(R.zip(trace.x), R.filter(x => !!x[1]), R.last)(trace.y)
  const arpus_annotations = arpus_data.map(a =>
    ({ xy: last(a), label: a.name, color: a.marker.color, yref: 'y3' })
  )

  const layout = {
    grid: {
      rows: 2,
      columns: 1,
      pattern: 'independent',
      roworder: 'bottom to top'
    },
    margin: {
      l: 0,
      b: 20,
      t: 30,
      pad: 0
    },
    // paper_bgcolor: '#7f7f7f',
    // plot_bgcolor: '#c7c7c7',
    legend: {
      x: -0.16,
      xanchor: 'left',
      // y: 1
    },
    showlegend: true,
    xaxis: {
      range: [d3.min(R.chain(d => d.x)(data || []) || []), d3.max(R.chain(d => d.x)(data || []))],
      showgrid: true,
      anchor: 'free',
    },
    yaxis: {
      title: 'Sales', anchor: 'free', position: 0,
      // overlaying: 'y',
      side: 'left',
      hoverformat: ',.0f',
      tickformat: ',s',
      domain: [0.67, 1],
      range: [0, !sales_data.y ? 100 : d3.max(sales_data.y.slice(-360))],
      showgrid: false,
    },

    yaxis2: {
      title: '', anchor: 'free', position: 1,
      overlaying: 'y',
      side: 'right',
      tickformat: ',.3s',
      domain: [0, 0.33],
    },
    yaxis3: {
      title: 'USD', anchor: 'free', position: 1,
      // overlaying: 'y',
      side: 'right',
      // hoverformat: ',.0f',
      tickformat: ',.2f',
      domain: [0, 0.33],
      range: [0, d3.max(ecpa_data.y.concat(arpus_data[3].y))],
    },

    yaxis4: {
      title: 'USD', anchor: 'free', position: 1,
      overlaying: 'y5',
      side: 'right',
      // hoverformat: ',.0f',
      tickformat: ',.3s',
      domain: [0.34, 0.66],
      range: [d3.max(revenue_data.flatMap(r => [...r.y])), 0],
    },
    yaxis5: {
      title: 'Sales', // anchor: 'free', position: 0,
      // overlaying: 'y',
      side: 'left',
      hoverformat: ',.0f',
      tickformat: ',s',
      domain: [0.34, 0.66],
      range: [d3.max(quarterly_sales_data.y.slice(-360)), 0],
      showgrid: false,
    },
    annotations: [...cohort_annotations, ...breakeven_annotations, ...arpus_annotations, ...revenue_annotations]
      .filter(a => !!a.xy)
      .map(({ xy, label, color, yref, xanchor, yanchor, visible, showarrow, ax, ay, ...rest }) => ({
        ...rest,
        xref: 'x',
        yref: yref,
        x: xy[0],
        y: xy[1],
        ay: ay || 0,
        ax: ax || 0,
        xanchor: xanchor || 'left',
        yanchor: yanchor || 'middle',
        // anchor: 'free',
        text: label,
        font: {
          family: 'Arial',
          size: 12,
          color: d35.rgb(color).darker(1).hex()
        },
        showarrow: showarrow || false,
        arrowhead: 2,
        visible: visible === false ? false : true
      }))
  }

  const handler = React.useMemo(() => myPlot => {
    
    myPlot.on('plotly_legenddoubleclick', ({ data, curveNumber }) => {
      console.log('plotly_legenddoubleclick', data, data[curveNumber])
    })

    myPlot.on('plotly_legendclick', d => {
      console.log('plotly_legendclick', d)
    })

    var auto = false
    myPlot.on('plotly_restyle', d => {
      if(auto) {
        return
      }
      window['myPlot'] = myPlot

      const visible = (d[0].visible[0] === 'legendonly') ? false : true;

      const annotation_key = myPlot.layout.annotations[d[1][0]].annotation_key;
      const annotations = myPlot.layout.annotations.map((a, i) => [a, i])
        .filter(([a, _]) => a.annotation_key === annotation_key)

      const revenueTraces = myPlot.data.map((a, i) => [a, i])
        .filter(([a, _]) => a.key === annotation_key)

      revenueTraces.forEach(([, i]) => {
        auto = true
        setTimeout(() => {
          auto = false
        }, 1000);
        Plotly.restyle(myPlot, { visible: visible ? true : 'legendonly'}, i)
      });


      
      annotations.forEach(([, i]) => {
        var astr = 'annotations[' + i + '].visible';
        Plotly.relayout(myPlot, astr, visible)

      });
    })
  }, [])

  return Plot([...data, sales_data, ...arpus_data, ecpa_data, quarterly_sales_data, ...revenue_data], layout, {
    responsive: true,
    autosize: true,
    showSendToCloud: !true
  }, handler)

}


const StringMultiSelect = MultiSelect.ofType<string>()
const renderStringItem : (selectedItems: string[]) => ItemRenderer<string> = (selectedItems) => (item, { modifiers, handleClick })  => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      icon={selectedItems.some(s => s === item) ? "tick" : "blank"}
      key={item}
      onClick={handleClick}
      text={item}
      shouldDismissPopover={false}
    />
  );
};
export function GrossMarginWithControls(props : GrossMarginProps & {
  onChange: (params: GrossMarginProps) => void
}) {
  const topTenGateways = useTopTen({ country: props.country, dimension: 'gateway', from: props.from, affiliates: new Set() })
    .map(t => t.dimension)
  return <div style={{
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }}>
    <div style={{margin: '12px'}}>
      <Checkbox checked={props.as_if} onChange={ev => {
        props.onChange({ ...props, as_if: (ev.target as HTMLInputElement).checked })
      }}>Analyze each cohort individually</Checkbox>
      <Slider
        min={0}
        max={48}
        stepSize={1}
        labelStepSize={6}
        onChange={ev => {
          props.onChange({ ...props, months_after_sale: ev })
        }}
        value={props.months_after_sale}
      />
      <StringMultiSelect 
        fill={true}
        resetOnQuery={true}
        resetOnSelect={true}
        popoverProps={{ minimal: true }}
        tagRenderer={s => <div>{s}</div>} 
        itemRenderer={renderStringItem(!props.gateway ? [] : props.gateway.split(','))} 
        items={topTenGateways} 
        onItemSelect={i => {
          console.log('onItemSelect', i)
          // props.onChange({gateway: })
        }} 
        selectedItems={!props.gateway ? [] : props.gateway.split(',')}
        
        />
    </div>
    <div style={{position: 'relative', flex: 1}}>
      <GrossMargin {...props} />
    </div>
  </div>
}

function Controls(props: GrossMarginProps & {
  onChange: (params: GrossMarginProps) => void
}) {
  const [selectedGateways, set_selectedGateways] = React.useState<Set<string>>(
    props.gateway === null ? new Set([]) : new Set(props.gateway.split(','))
  )
}