import React from 'react'
import * as R from 'ramda';
import enqueue from './_fetchQueue'

export type IProps = {
  country: string;
  date_from: string;
  date_to: string;
  resolution: string;
  gateway: null | string;
  affiliate: null | string;
  operator: null | string;
  page: null | string;
  service1: null | string;
}


export function useGrossMargin_WithFilters({
  country,
  date_from,
  date_to,
  resolution,
  gateway,
  affiliate,
  operator,
  page,
  service1
}: IProps) {
  const [data, set_data] = React.useState([]);
  React.useEffect(() => {
    set_data([])
    enqueue(() => fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rN7GhNp/versions/21/execute/7200/query?from=${date_from}&to=${date_to}&countries=${country || '-'}&gateways=${gateway || '-'}&affiliates=${affiliate || '-'}&operators=${operator || '-'}&service1=-&pages=-&resolution=${resolution}`)
      .then(x => x.json())
      // .then(R.pipe(
      //   R.map((x: any) =>
      //     ({ ...x, quarter: new Date(x.quarter), day: new Date(x.day) })
      //   )
      // ))
      .then(x => set_data(x)));
  }, [
    country,
    date_from,
    date_to,
    resolution,
    window,
    gateway,
    affiliate,
    operator,
    page,
    service1
  ]);

  return data
}