import React, { useMemo } from 'react';
import { Plot, d3 } from './Plot';
import * as R from 'ramda'
import { useCountryARPU_WithFilters } from './hooks/useCountryARPU';
import mkStats from './utils/mkStats';
export function CountryARPU({ country, from, window, scale, gateway, affiliate, publisher, operator, page, service1 }: {
  country: string;
  from: number;
  window: number;
  scale: 'auto' | 'linear' | 'log';
  gateway: null | string;
  affiliate: null | string;
  publisher: null | string;
  operator: null | string;
  page: null | string;
  service1: null | string;
}) {
  const data = useCountryARPU_WithFilters({ country, from, window, gateway, affiliate, publisher, operator, page, service1 })
  const arpus = ['week_1', 'week_2', 'week_3', 'month_1', 'month_2', 'month_3', 'month_4', 'month_5']
  const stats = useMemo(() => mkStats(['sales'])(data), [data])
  const enoughSalesCondition = useMemo(() => stats['sales'].mean > 30 ? x => x.sales > 5 : x => x.sales > 1, [stats])

  const weightedArpusStats = useMemo(() => mkStats(
    R.chain(a => [`${a}_weighted_arpu`, `sales_${a}`], arpus).concat(['weighted_ecpa', 'sales'])
  )(
    data.filter(d => enoughSalesCondition(d)).map(d =>
      R.merge({ ...d, weighted_ecpa: d.ecpa * d.sales },
        R.fromPairs(
          arpus.map(a => [`${a}_weighted_arpu`, d[`arpu_${a}`] * d[`sales_${a}`]]) as Array<[string, number]>
        )
      ))
  )
    , [arpus, data, enoughSalesCondition])

  const week_2_weighted_arpu = weightedArpusStats.week_2_weighted_arpu.sum / weightedArpusStats.sales_week_2.sum

  const dataWithEnoughSales = useMemo(() => data.map(x => enoughSalesCondition(x) ? x : R.merge(x, {
    ecpa: null,
    ...R.pipe(
      R.map(t => [`arpu_${t}`, null])
      , R.fromPairs
    )(['week_1', 'week_2', 'week_3', 'month_1', 'month_2', 'month_3', 'month_4', 'month_5'])
  })), [data, enoughSalesCondition]);

  const arpuLines = ['week_1', 'week_2', 'week_3', 'month_1', 'month_2', 'month_3', 'month_4', 'month_5'].map(t => ({
    x: dataWithEnoughSales.map(x => x.date),
    y: dataWithEnoughSales.map(x => x[`arpu_${t}`]),
    yaxis: 'y2',
    xaxis: 'x',
    type: "scatter",
    mode: "lines+points",
    // marker: { color: "#ff7f0e" },
    name: t,
  }))


  const chartMax = d3.max(data, e => d3.max([e.arpu_month_1, e.arpu_week_1, e.arpu_week_2, e.arpu_month_4, e.ecpa]))
  const chartMin = d3.min(data, e => d3.min([e.arpu_month_1, e.arpu_week_1, e.arpu_week_2, e.arpu_month_4, e.ecpa]))

  const traces = React.useMemo(() => [
    {
      type: "bar",
      yaxis: 'y1',
      x: data.map(x => x.date),
      y: data.map(x => x.sales),
      name: 'Avg Sales',
      marker: {
        color: '#f2f2f2BB'
      },
    },
    {
      x: dataWithEnoughSales.map(x => x.date),
      y: dataWithEnoughSales.map(x => x.ecpa),
      yaxis: 'y2',
      xaxis: 'x',
      type: "scatter",
      mode: "lines+points",
      line: {
        dash: 'dashdot',
        width: 2
      },
      marker: { color: "#fbb4ae" },
      name: 'eCPA',
    },
    ...arpuLines
  ], [data, dataWithEnoughSales, arpuLines])

  const scaleType = scale === 'auto' ? (
    Math.abs(Math.log(chartMax) - Math.log(chartMin)) > 10 ? 'linear' : 'log') : scale


  const last = (trace, selector = R.last) => {
    if (!trace || trace.x.length === 0 || trace.y.length === 0) {
      return null
    }
    const point = R.pipe(R.zip(trace.x), R.filter(x => !!x[1]), selector)(trace.y)
    if (!point)
      return null
    const [x, y] = point
    return [x, scaleType === 'linear' ? y : Math.log(y) / Math.log(10)]
  }

  const cohort_annotations =
    [{ xy: last(arpuLines[0], xs => xs[xs.length - 5 > 0 ? xs.length - 5 : xs.length - 1]), label: 'ARPU 7', yanchor: 'top' },
    { xy: last(arpuLines[1]), label: 'ARPU 14' },
    { xy: last(arpuLines[2]), label: 'ARPU 21' },
    { xy: last(arpuLines[3]), label: 'ARPU 30' },
    { xy: last(arpuLines[4]), label: 'ARPU 60' },
    { xy: last(arpuLines[5]), label: 'ARPU 90' }
    ]
      .map(a => ({ ...a, yref: 'y2' }))
      .filter(a => !!a.xy)

  return (
    <div style={{ position: 'absolute', top: 0, left: '0.2em', right: 0, bottom: 0 }}>
      <Plot data={traces} layout={{
        showlegend: !true,
        legend: { "orientation": "h" },
        margin: {
          t: 0, b: 30, l: 50, r: 20
        },
        xaxis: {
          domain: [0, 0.95]
        },
        yaxis: {
          title: 'Sales', anchor: 'x',
          hoverformat: ',.0f',
          tickformat: ',s',
          domain: [0, 1],
        },
        yaxis2: {
          title: 'USD',
          type: scaleType,
          titlefont: { color: 'rgb(148, 103, 189)' },
          tickfont: { color: '#9467bd' },
          anchor: 'free',
          position: 0.95,
          overlaying: 'y',
          side: 'right',
          showgrid: false,
          hoverformat: week_2_weighted_arpu < 0.1 ? ',.3f' : ',.2f',
          tickformat: week_2_weighted_arpu < 0.1 ? ',.3f' : ',.2f',
          domain: [0, 1],
          rangemode: 'tozero',
          zeroline: false,
          // range: [0, 1.5] // [0, d3.max([d3.max(data, e => e.arpu_month_3), d3.max(data, e => e.arpu_month_5)]) * 1.05]
        },
        autosize: true,
        annotations: (cohort_annotations as any[]).map(({ xy, label, color, yref, xanchor, yanchor, visible, showarrow, ax, ay, ...rest }) => ({
          ...rest,
          xref: 'x',
          yref: yref,
          x: xy[0],
          y: xy[1],
          ay: ay || 0,
          ax: ax || 0,
          xanchor: xanchor || 'left',
          yanchor: yanchor || 'middle',
          // anchor: 'free',
          text: label,
          font: {
            family: 'Arial',
            size: 12,
            // color: d35.rgb(color).darker(1).hex()
          },
          showarrow: showarrow || false,
          arrowhead: 2,
          visible: visible === false ? false : true
        }))
      }}
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }} />
      {
        data.length &&
        <div style={{ position: 'absolute', left: 0, right: 0, bottom: '4ex' }}>
          <a target="_blank" rel="noopener noreferrer"
            href={`http://pipend.sam-media.com:8095/?cpc_arpu_list=value&cpc_arpu_list=${
              ['month_1', 'month_2', 'month_3', 'month_4', 'month_5']
                .map(x => weightedArpusStats[`${x}_weighted_arpu`].sum / weightedArpusStats[`sales_${x}`].sum)
                .filter(x => !isNaN(x))
                .map(x => d3.format('0.3f')(x))
                .join('+')
              }&cpc_ecpa=value&cpc_ecpa=${
              d3.format('0.3f')(weightedArpusStats.weighted_ecpa.sum / weightedArpusStats.sales.sum)
              }&cpc_sales=value&cpc_sales=${
              Math.round(weightedArpusStats.sales.mean)
              }&cpc_delay=value&cpc_delay=3&cpc_calculate=n_clicks&cpc_calculate=1`}>
            ROI
          </a>
        </div>
      }
    </div>
  );
}
