import React from 'react';
import * as R from 'ramda'
import { d3 } from './Plot';
import "./SalesTargets.scss"

const change = (a: any, b: any) => b === 0 ? NaN : a / b - 1
const metric = lens => (n: any, b: any) => {
  const value = R.view(lens)(n)
  const bValue = R.view(lens)(b)
  return {
    value,
    change: change(value, bValue)
  }
}

export const stats = R.pipe(
  R.filter((d: any) => d.rnk % 7 === 0)
  , R.map(x =>
    ({
      ...R.set(R.lensProp('avg_ecpa'))(x.avg_cost / x.avg_sales, x),
      ...R.set(R.lensProp('avg_pixel_rate'))(x.avg_pixels / x.avg_sales, x),
      ...R.set(R.lensProp('date'))(new Date(x.day), x)
    })
  )
  , x => R.zip(x, R.drop(1)(x))
  , R.map(([n, b]) => ({
    date: n.date
    , sales: metric(R.lensProp('avg_sales'))(n, b)
    , pixels: metric(R.lensProp('avg_pixels'))(n, b)
    , pixel_rate: metric(R.lensProp('avg_pixel_rate'))(n, b)
    , cost: metric(R.lensProp('avg_cost'))(n, b)
    , revenue: metric(R.lensProp('avg_revenue'))(n, b)
    , ecpa: metric(R.lensProp('avg_ecpa'))(n, b)
    , cq: metric(R.lensProp('avg_cq'))(n, b)
  }))
)


function changeCSS(m) {
  return { '--change': `'${d3.format('%')(Math.abs(m.change))}'`, '--color': m.change > 0 ? `#2196f3b5` : `#ff5722b5` } as any
}

function SalesTargets({ actual, min_target, max_target }) {
  const achievement = min_target === max_target ?  actual / max_target : (actual - min_target) / (max_target - min_target)
  const below_min_target = actual < min_target
  return <div className={`metric-box ${below_min_target ? 'below_min_target' : ''}`}>
    <div className="metric-label">Sales</div>
    <div className="metric-values">
      <div className="actual">{isNaN(actual) ? '' : d3.format(',.0f')(actual)}</div>
      <div className="min_target"><span className='label'>Min: </span>{d3.format(',.0f')(min_target)}</div>
      <div className="max_target"><span className='label'>Max: </span>{d3.format(',.0f')(max_target)} {isNaN(achievement) || achievement === Infinity ? '' : <>({d3.format('%.0f')(achievement)})</> }</div>
    </div>
  </div>
}

function ECPATargets({ actual, target }) {
  const diff = actual / target

  return <div className={`metric-box ${diff > 1.1 ? 'high_ecpa' : ''}`}>
    <div className="metric-label">eCPA</div>
    <div className="metric-values">
      <div className="actual">{isNaN(actual) ? '-' : d3.format(',.2f')(actual)}</div>
      <div className="target"><span className='label'>Target: </span>{d3.format(',.2f')(target)}</div>
    </div>
  </div>
}

export function PlotSalesTargets({ data, targets, period }) {
  console.log('PlotSalesTargets', data, targets)
  const chainSalesRatio = !data ? NaN : data.chain_sales.value / ((data.aff_sales.value || 0) + (data.dmb_sales.value || 0))
  return !!data && !!targets ? <div className="sales-targets-container">
    <div className="source-metrics dmb">
      <div className="source-metrics-label">DMB</div>
      <div className="source-metrics-content">
        <SalesTargets actual={data.dmb_sales.value} min_target={targets.min_dmb_sales} max_target={targets.max_dmb_sales} />
        <ECPATargets actual={data.dmb_ecpa.value} target={targets.ecpa_target} />
      </div>
    </div>
    <div className="source-metrics affiliates">
      <div className="source-metrics-label">Affiliates</div>
      <div className="source-metrics-content">
        <SalesTargets actual={data.aff_sales.value} min_target={targets.min_aff_sales} max_target={targets.max_aff_sales} />
        <ECPATargets actual={data.aff_ecpa.value} target={targets.ecpa_target} />
      </div>
    </div>
    <div className="source-metrics affiliates">
      <div className="source-metrics-label">Past {period} days</div>
      <div className="source-metrics-content">
        <div className={`metric-box`}>
          <div className="metric-label">Chain+ Sales</div>
          <div className="metric-values">
            <div className="actual">{isNaN(data.chain_sales.value) ? '' : d3.format(',.0f')(data.chain_sales.value)}</div>
            {/* <div className="min_target"><span className='label'>Min: </span>{d3.format(',.0f')(min_target)}</div> */}
            <div className="max_target"><span className='label'>Of Total: </span>{isNaN(chainSalesRatio) || chainSalesRatio === Infinity ? '' : <>({d3.format('%.0f')(chainSalesRatio)})</>}</div>
          </div>
        </div>
        {/* <ECPATargets actual={data.aff_ecpa.value} target={targets.ecpa_target} /> */}
      </div>
    </div>
  </div>
    : <div className="sales-targets-container"></div>
}