import * as React from 'react'
import './Dashboard.scss'
import { useCountryRevenueCostECPA_WithFilters } from './hooks/useCountryRevenueCostECPA'
import { useLatestTargets } from './hooks/useLatestTargets'
import { PlotCountryTimeSeries } from './CountryTimeSeries'
import { Legend, RevenueBreakdownStack, SalesBreakdownStack } from './RevenueOrSalesBreakdownStack'
import { CountryARPU } from './CountryARPU'
import { ARPUCQECPAByDimensionScatter, ARPUn } from './ARPUCQECPAByDimensionScatter'
import useTopTen from './hooks/useTopTen'
import { LandingPagesCR } from './LandingPagesCRTable'
import { DimensionTableSummary } from './DimensionSummaryTable'
import * as R from 'ramda'
import { PlotCountryTableSummary, stats } from './CountryTable'
import { PlotSalesTargets } from './SalesTargets'
import { OuisysCampaignsSummaryTable } from './OuisysCampaignsSummaryTable'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'

export type IParams = {
  country: string;
  gateway: null | string;
  operator: null | string;
  affiliate: null | string;
  publisher: null | string;
  page: null | string;
  service1: null | string;
  from: number;
}

export type IChangeParams = {
  country: string;
  gateways: null | string[];
  operators: null | string[];
  affiliates: null | string[];
  publishers: null | string[];
  pages: null | string[];
  service1s: null | string[];
  from: number;
}

export type IProps = IParams & {
  onChange: (params: IChangeParams) => void
}

function MultiSelect({ options, onChange, placeholder }: {
  options: { name: string, value: string, selected: boolean }[]
  , onChange: (values: Set<string>) => void
  , placeholder: string
}) {
  const myOptions = options.map(o => ({ ...o, label: o.name }))
  return <div style={{ width: '400px' }}>
    {options.length === 0 ? <></> :
      <Select
        placeholder={placeholder}
        options={myOptions}
        defaultValue={myOptions.filter(o => o.selected)}
        isMulti
        styles={{
          // option: (styles) => ({...styles, color: 'red'})
        }}
        onChange={values => {
          onChange(new Set(values.map(e => e.value)))
        }}
      ></Select>
    }
  </div>
}

function MultiSelect_({ options, onChange }: {
  options: { name: string, value: string, selected: boolean }[]
  , onChange: (values: Set<string>) => void
}) {
  const multiSelectRef = React.useRef<HTMLSelectElement>(null)
  React.useEffect(() => {
    const selectedValues = new Set(options.filter(o => o.selected).map(o => o.value))
    multiSelectRef.current.querySelectorAll('option').forEach(o => {
      if (selectedValues.has(o.value)) {
        o.selected = true;
      }
    });
  }, [options.length, options])
  return <>
    <select ref={multiSelectRef} multiple onChange={() => {
      onChange(new Set([...multiSelectRef.current.querySelectorAll("option")]
        .filter(o => o.selected).map(o => o.value)))
    }}>
      {
        options.map(t =>
          <option key={t.value} value={t.value}>{t.name}</option>
        )
      }
    </select>
    <button onClick={() => {
      multiSelectRef.current.querySelectorAll('option').forEach(o =>
        o.selected = false
      );
      onChange(new Set([]))
    }}>All</button>
  </>
}

function Widget({ row, column, title, children, controls }: React.PropsWithChildren<{ row: string, column: string, title: string, controls?: React.ReactElement | null }>) {
  return <div className='dashboard-box' style={{ '--row': row, '--column': column, ...(title != null ? { '--header-height': '32px' } : { '--content-margin-top': 0, '--header-height': 0, '--content-bottom': 0, '--border': 0 }) } as any}>
    {title != null && <div className="header">
      <div className="title">{title}</div>
      {
        !!controls ? <div className='controls'>{controls}</div> : <></>
      }
    </div>
    }
    <div className="content">{children}</div>
  </div>
}

function WindowSelect({ set, value, label }) {
  return <>{label}:&nbsp;
    <select value={value} onChange={
      event => set(+event.target.value)
    }>
      {[1, 7, 8, 14, 15, 21, 22, 30, 31].map(d => <option key={d} value={d}>{d}</option>)}
    </select>
  </>
}

function ArpuNSelect({ set, value, label }) {
  return <>{label}:&nbsp;
    <select value={value} onChange={
      event => set(event.target.value)
    }>
      {['week_1', 'month_1', 'month_2', 'month_3'].map(d => <option key={d} value={d}>{d}</option>)}
    </select>
  </>
}

export default function Dashboard({
  country, gateway, operator, affiliate, publisher, from, page, service1, onChange
}: IProps) {
  const [cost_window, set_cost_window] = React.useState(7)
  const [revenue_window, set_revenue_window] = React.useState(31)
  const [arpu_window, set_arpu_window] = React.useState(7)
  const [arpu_scale, set_arpu_scale] = React.useState<"auto" | "linear" | "log">('auto');
  const [scatter_arpu_n, set_scatter_arpu_n] = React.useState('week_1' as ARPUn)
  const arpu_n_long_label = scatter_arpu_n.split('_')[0].toUpperCase() + ' ' + scatter_arpu_n.split('_')[1]
  const revenueCostECPAData = useCountryRevenueCostECPA_WithFilters({ country, from, cost_window, revenue_window, sales_from: null, sales_to: null, gateway, affiliate, publisher, operator, page, service1 })
  const salesTargets = useLatestTargets();

  const [selectedFrom, set_selectedFrom] = React.useState(from)
  const [selectedAffiliates, set_selectedAffiliates] = React.useState<Set<string>>(
    affiliate === null ? new Set([]) : new Set(affiliate.split(','))
  )
  const [selectedPublishers, set_selectedPublishers] = React.useState<Set<string>>(
    publisher === null ? new Set([]) : new Set(publisher.split(','))
  )
  const [selectedOperators, set_selectedOperators] = React.useState<Set<string>>(
    operator === null ? new Set([]) : new Set(operator.split(','))
  )
  const [selectedPages, set_selectedPages] = React.useState<Set<string>>(
    page === null ? new Set([]) : new Set(page.split(','))
  )
  const [selectedService1s, set_selectedService1s] = React.useState<Set<string>>(
    service1 === null ? new Set([]) : new Set(service1.split(','))
  )
  const [selectedGateways, set_selectedGateways] = React.useState<Set<string>>(
    gateway === null ? new Set([]) : new Set(gateway.split(','))
  )

  const isCountryDashboard = country != null && [gateway, operator, affiliate, page, service1].every(t => t === null)
  const isAffiliateDashboard = affiliate != null && affiliate.split(',').length === 1
  const isGatewayDashboard = gateway != null && gateway.split(',').length === 1;

  React.useEffect(() => {
    window.addEventListener('queue-size-changed', (ev: CustomEvent) => {
      // console.log('queue.size', ev.detail)
    })
  }, [])

  const topTenAffiliates = useTopTen({ country, dimension: 'affiliate_id', from, affiliates: new Set() })
  const topTenOperators = useTopTen({ country, dimension: 'operator_code', from, affiliates: new Set() })
  const topTenPages = useTopTen({ country, dimension: 'handle_name', from, affiliates: new Set() })
  const topTenServices = useTopTen({ country, dimension: 'service_identifier1', from, affiliates: new Set() })
  const topTenGateways = useTopTen({ country, dimension: 'gateway', from, affiliates: new Set() })
  const topTenPublishers = useTopTen({ country, dimension: 'pubid', from, affiliates: selectedAffiliates })

  const legend_orientation_v_show_false = React.useMemo<Legend>(() => (
    { orientation: 'v', showlegend: false }
  ), []);
  const legend_orientation_v = React.useMemo<Legend>(() => (
    { orientation: 'v' }
  ), []);
  const legend_orientation_h = React.useMemo<Legend>(() => (
    { orientation: 'h' }
  ), []);

  const select_gateways = React.useMemo(() =>
    <MultiSelect
      placeholder="Gateways"
      options={topTenGateways.map(t => ({
        name: t.dimension,
        value: t.dimension,
      })).map(t => ({ name: t.name, value: t.value, selected: selectedGateways.has(t.value) }))}
      onChange={values => set_selectedGateways(values)}
    />
    , [topTenGateways, selectedGateways])

  const select_affiliates = React.useMemo(() =>
    <MultiSelect
      placeholder="Affiliates"
      options={topTenAffiliates.map(t => ({
        name: t.dimension.split(':')[0],
        value: t.dimension.split(':')[1],
      })).map(t => ({ name: t.name, value: t.value, selected: selectedAffiliates.has(t.value) }))}
      onChange={values => set_selectedAffiliates(values)}
    />
    , [topTenAffiliates, selectedAffiliates])

  const select_operators = React.useMemo(() => <MultiSelect
    placeholder="Operators"
    options={topTenOperators.map(t => ({
      name: t.dimension,
      value: t.dimension,
    })).map(t => ({ name: t.name, value: t.value, selected: selectedOperators.has(t.value) }))}
    onChange={values => set_selectedOperators(values)}
  />, [topTenOperators, selectedOperators])

  return <div className={`dashboard-container ${isCountryDashboard ? 'with-targets' : ''}`}>
    <div className='dashboard-box dashboard-head' style={{ '--row': 'row-header', '--column': 'col-1 / span 2' } as any}>{country}

      {select_gateways}

      {select_affiliates}

      {
        selectedAffiliates.size === 1 &&
        <MultiSelect
          placeholder="Publishers"
          options={topTenPublishers.map(t => ({
            name: t.dimension,
            value: t.dimension,
          })).map(t => ({ name: t.name, value: t.value, selected: selectedPublishers.has(t.value) }))}
          onChange={values => set_selectedPublishers(values)}
        />
      }

      {select_operators}

      <MultiSelect
        placeholder="Pages"
        options={topTenPages.map(t => ({
          name: t.dimension,
          value: t.dimension,
        })).map(t => ({ name: t.name, value: t.value, selected: selectedPages.has(t.value) }))}
        onChange={values => set_selectedPages(values)}
      />

      <MultiSelect
        placeholder="Services"
        options={topTenServices.map(t => ({
          name: t.dimension,
          value: t.dimension,
        })).map(t => ({ name: t.name, value: t.value, selected: selectedService1s.has(t.value) }))}
        onChange={values => set_selectedService1s(values)}
      />

      <select value={selectedFrom} onChange={(e) => {
        set_selectedFrom(parseInt(e.target.value))
      }}>
        {
          R.range(7, 7 * 53).filter(d => d % 7 === 0).map(d =>
            <option key={d} value={-1 * d}>
              {d}
            </option>
          )
        }
      </select>


      <button onClick={() => {
        onChange({
          country: country,
          affiliates: [...selectedAffiliates.values()],
          publishers: [...selectedPublishers.values()],
          gateways: [...selectedGateways.values()],
          operators: [...selectedOperators.values()],
          pages: [...selectedPages.values()],
          from: selectedFrom,
          service1s: [...selectedService1s.values()]
        })
      }}>GO</button>
    </div>
    {isCountryDashboard &&
      <Widget row="row-targets" column="col-1 / span 2" title={null} controls={
        <>

        </>
      }>
        <PlotSalesTargets period={cost_window} data={R.head(stats(revenueCostECPAData))} targets={salesTargets.find(t => t.country_code === country)} />
      </Widget>
    }
    <Widget row="row-ouisys-summary" column="col-1 / span 2" title="Ouisys Campaigns">
      <OuisysCampaignsSummaryTable {...{ affiliate, publisher, country, from, gateway, operator, page, service1 }} />
    </Widget>
    <Widget row="row-summary" column="col-1 / span 1" title="Summary" controls={
      <>
        <WindowSelect set={set_revenue_window} value={revenue_window} label="Rev W" />
        &nbsp;
        <WindowSelect set={set_cost_window} value={cost_window} label="Cost W" />
      </>
    }>
      <PlotCountryTimeSeries data={revenueCostECPAData} />
    </Widget>
    <Widget row="row-summary" column="col-2 / span 1" title="Weekly Summary">
      <PlotCountryTableSummary data={stats(revenueCostECPAData)} />
    </Widget>
    <Widget row="row-arpu" column="col-1 / col-1" title="ARPU" controls={
      <>
        {['auto', 'linear', 'log'].map(s => <><label><input
          onChange={e => {
            set_arpu_scale(s as any)
          }}
          type="radio" value={s} checked={s === arpu_scale} name="arpu_scale" />{s}</label>&nbsp;</>)}
        &nbsp;&nbsp;
        <WindowSelect set={set_arpu_window} value={arpu_window} label="ARPU W" />
      </>
    }>
      <CountryARPU {...{ affiliate, publisher, country, from, gateway, operator, page, service1, window: arpu_window, scale: arpu_scale }} />
    </Widget>
    <Widget row="row-arpu" column="col-2 / span 1" title="Landing Pages">
      <LandingPagesCR {...{ affiliate, publisher, country, from, gateway, operator, service1 }} />
    </Widget>
    <Widget row="row-affiliates" column="col-1 / col-2" title={`Sales by ${isAffiliateDashboard ? 'Publishers' : `Affiliates`}`}>
      <SalesBreakdownStack legend={{ orientation: 'h' }}  {...{ affiliate, publisher, country, from, gateway, operator, page, service1 }} dimension={isAffiliateDashboard ? 'pubid' : 'affiliate_id'} resolution={1} window={7} mode='line' />
    </Widget>
    <Widget row="row-affiliates" column="col-2 / span 1" title={`eCPA vs APRU ${arpu_n_long_label} per ${isAffiliateDashboard ? 'Publisher' : `Affiliate`}`} controls={
      <ArpuNSelect set={set_scatter_arpu_n} value={scatter_arpu_n} label="For" />
    }>
      <ARPUCQECPAByDimensionScatter {...{ arpu_n: scatter_arpu_n, affiliate, publisher, country, from, gateway, operator, page, service1 }} to={0} dimension={isAffiliateDashboard ? 'pubid' : 'affiliate_id'} />
    </Widget>
    {/* <div className='dashboard-box' style={{ '--row': 'row-affiliates', '--column': 'col-2 / span 1' } as any}>
      
    </div> */}
    <Widget row="row-services" column="col-2 / span 1" title={`eCPA vs APRU ${arpu_n_long_label} per service_identifier1`} controls={
      <ArpuNSelect set={set_scatter_arpu_n} value={scatter_arpu_n} label="For" />
    }>
      <ARPUCQECPAByDimensionScatter {...{ arpu_n: scatter_arpu_n, affiliate, publisher, country, from, gateway, operator, page, service1 }} to={0} dimension={'service_identifier1'} />
    </Widget>



    {<>
      <div className='dashboard-box split-top-bottom' style={{ '--row': 'row-gateways', '--column': 'col-1 / col-2' } as any}>
        <div className='top'>
          <RevenueBreakdownStack legend={legend_orientation_v_show_false} {...{ affiliate, publisher, country, from, gateway, operator, page, service1 }} dimension={isGatewayDashboard ? 'service_identifier1' : 'gateway'} resolution={7} window={revenue_window} mode='line' />
        </div>
        <div className='bottom'>
          <SalesBreakdownStack legend={legend_orientation_h}  {...{ affiliate, publisher, country, from, gateway, operator, page, service1 }} dimension={isGatewayDashboard ? 'service_identifier1' : 'gateway'} resolution={7} window={7} mode='line' />
        </div>
      </div>
      <Widget row="row-operators" column="col-2 / span 1" title={`eCPA vs APRU ${arpu_n_long_label} per gateway`} controls={
        <ArpuNSelect set={set_scatter_arpu_n} value={scatter_arpu_n} label="For" />
      }>
        <ARPUCQECPAByDimensionScatter {...{ arpu_n: scatter_arpu_n, affiliate, publisher, country, from, gateway, operator, page, service1 }} to={0} dimension='gateway' />
      </Widget>
    </>
    }
    <div className='dashboard-box' style={{ '--row': 'row-gateways', '--column': 'col-2 / span 1' } as any}>
      <DimensionTableSummary dimension={isAffiliateDashboard ? 'pubid' : 'affiliate_id'} {...{ affiliate, publisher, country, from, gateway, operator, page, service1 }} />
    </div>
    {<>
      <div className='dashboard-box split-top-bottom' style={{ '--row': 'row-operators', '--column': 'col-1 / col-2' } as any}>
        <div className='top'>
          <RevenueBreakdownStack legend={legend_orientation_h}  {...{ affiliate, publisher, country, from, gateway, operator, page, service1 }} dimension='operator_code' resolution={7} window={revenue_window} mode='line' />
        </div>
        <div className='bottom'>
          <SalesBreakdownStack legend={legend_orientation_h}  {...{ affiliate, publisher, country, from, gateway, operator, page, service1 }} dimension='operator_code' resolution={7} window={7} mode='line' />
        </div>
      </div>
    </>
    }
  </div>
}