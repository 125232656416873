import React from 'react';
import * as R from 'ramda'
import { BrowserRouter as Router, Route, RouteComponentProps } from "react-router-dom";
import './App.scss';
import { CountryTimeSeries } from './CountryTimeSeries';
import { GrossMarginWithControls } from './GrossMargin';
import { ARPUCQECPAByDimensionScatter } from './ARPUCQECPAByDimensionScatter';
import { CountryTable } from './CountryTable';
import { CountryARPU } from './CountryARPU';
import { EmpelloTicketsCountryStack } from './EmpelloTicketsCountryStack';
import { CountryTimeSeriesProfit } from './CountryTimeSeriesProfit';
import Dashboard from './Dashboard';
import { StaticContext } from 'react-router';
import { RevenueBreakdownStack } from './RevenueOrSalesBreakdownStack';
import Live from './Live';

function paramsToNumber(params, name, def) {
  const v = params.get(name)
  if(isNaN(v) || v === null) {
    return def;
  } else {
    return +v;
  }
}

function paramsToBoolean(params, name) {
  const v = params.get(name)
  return !!/true/i.exec(v)
}

function parseToEnum(enums, params, name, def) {
  const v = params.get(name)
  if(!v || !enums.some(e => e === v)) {
    return def
  } else {
    return v
  }
}

function getStandardParams({ match, location }: RouteComponentProps<any, StaticContext, any>, defaults?) {
  const country = match.params.country
  const query = new URLSearchParams(location.search);
  const gateway = query.get('gateway')
  const affiliate = query.get('affiliate')
  const publisher = query.get('publisher')
  const operator = query.get('operator')
  const page = query.get('page')
  const service1 = query.get('service1')
  const from = paramsToNumber(query, 'from', R.view(R.lensProp("from"), defaults) || -7*22)

  return {
    country, gateway, affiliate, publisher, operator, page, from, service1,
    params: match.params,
    query
  }
}

const App: React.FC = () => {

  return (
    <Router>
      <div className="App">
        <header className="App-header1">


        </header>


      </div>
      <Route path="/" exact render={() => 'XYZ'} />
      <Route path="/live" exact render={() => <Live /> } />
      <Route path="/dashboard/:country"  render={args => {
        const history = args.history
        const location = args.location
        const { country, affiliate, publisher, from, operator, gateway, page, service1 } = getStandardParams(args, {from: -7*11})
        return <Dashboard country={country} gateway={gateway} operator={operator} page={page} from={from} affiliate={affiliate} publisher={publisher} service1={service1}
          onChange={params => {
            const url = new URL(`${window.location.protocol}//${window.location.hostname}${location.pathname}`)
            url.pathname = `/dashboard/${params.country}`
            if (!!params.gateways && params.gateways.length > 0) {
              url.searchParams.set('gateway', params.gateways.join(','))
            }
            if(!!params.affiliates && params.affiliates.length > 0) {
              url.searchParams.set('affiliate', params.affiliates.join(','))
            }
            if (!!params.publishers && params.publishers.length > 0) {
              url.searchParams.set('publisher', params.publishers.join(','))
            }
            if (!!params.operators && params.operators.length > 0) {
              url.searchParams.set('operator', params.operators.join(','))
            }
            if (!!params.pages && params.pages.length > 0) {
              url.searchParams.set('page', params.pages.join(','))
            }
            if (!!params.service1s && params.service1s.length > 0) {
              url.searchParams.set('service1', params.service1s.join(','))
            }
            if (!!params.from) {
              url.searchParams.set('from', params.from.toString())
            }
            history.push(url.pathname + '?' + [...url.searchParams].map(x => x.join('=')).join('&'))
          }} />
      }} />
      <Route path="/revenue-cost-sales-ecpa/table/:country" exact render={args => {
        const { country, gateway, affiliate, publisher, operator } = getStandardParams(args)
        return <CountryTable country={country} gateway={gateway} affiliate={affiliate} publisher={publisher} operator={operator} /> } } />
      <Route path="/revenue-cost-sales-ecpa/:country" exact render={args => {
        const { country, gateway, affiliate, publisher, operator, page, from, query, service1 } = getStandardParams(args)
        const costWindowSize = paramsToNumber(query, 'window', 7)
        const revenueWindowSize = paramsToNumber(query, 'revenue-window', 31)
        return <CountryTimeSeries from={from} cost_window={costWindowSize} revenue_window={revenueWindowSize} country={country} gateway={gateway} affiliate={affiliate} publisher={publisher} operator={operator} page={page} service1={service1} />
      } } />
      <Route path="/affiliates-scatter/:country" exact render={args => {
        const { country, gateway, affiliate, publisher, operator, from, query, page, service1 } = getStandardParams(args)
        const dimension = query.get("dimension") || "affiliate_id"
        const to = paramsToNumber(query, 'to', -7)
        return <ARPUCQECPAByDimensionScatter arpu_n={'week_1'} dimension={dimension} from={from} to={to} country={country} gateway={gateway} affiliate={affiliate} publisher={publisher} operator={operator} page={page} service1={service1} />
      }} />
      <Route path="/profit/:country" exact render={args => {
        const { country, gateway, affiliate, operator, from, query } = getStandardParams(args, {
          from: -180
        })
        const costWindowSize = paramsToNumber(query, 'window', 30)
        const revenueWindowSize = paramsToNumber(query, 'revenue-window', 31)
        const salesFrom = paramsToNumber(query, 'sales-from', -180)
        const salesTo = paramsToNumber(query, 'sales-to', 0)
        return <CountryTimeSeriesProfit from={from} cost_window={costWindowSize} revenue_window={revenueWindowSize} sales_from={salesFrom} sales_to={salesTo} country={country} gateway={gateway} affiliate={affiliate} operator={operator} />
      }} />
      <Route path="/arpu/:country" exact render={args => {
        const { country, gateway, affiliate, publisher, operator, page, from, query, service1 } = getStandardParams(args)
        const windowSize = paramsToNumber(query, 'window', 7)
        const scale = parseToEnum(['auto', 'linear', 'log'], query, 'scale', 'auto')
        return <CountryARPU country={country} from={from} window={windowSize} scale={scale} gateway={gateway} affiliate={affiliate} publisher={publisher} operator={operator} page={page} service1={service1} />
      } } />
      <Route path="/margin/:country" exact render={args => {
        const { country, gateway, affiliate, operator, from, query, service1, page } = getStandardParams(args)
        const arpu_window = paramsToNumber(query, 'arpu_window', 7)
        const as_if = paramsToBoolean(query, 'as_if')
        const months_after_sale = paramsToNumber(query, "months_after_sale", 24)
        const history = args.history
        const location = args.location
        return <GrossMarginWithControls country={country} from={from} arpu_window={arpu_window} gateway={gateway} affiliate={affiliate} operator={operator} page={page} months_after_sale={months_after_sale} service1={service1} as_if={as_if} 
          onChange={params => {
            const url = new URL(`${window.location.protocol}//${window.location.hostname}${location.pathname}${location.search}`)
            url.pathname = `/margin/${params.country}`
            if (params.as_if != as_if) {
              url.searchParams.set('as_if', params.as_if ? 'true' : 'false')
            }
            if (params.months_after_sale != months_after_sale) {
              url.searchParams.set('months_after_sale', params.months_after_sale.toString())
            }
            // if (!!params.affiliates && params.affiliates.length > 0) {
            //   url.searchParams.set('affiliate', params.affiliates.join(','))
            // }
            // if (!!params.publishers && params.publishers.length > 0) {
            //   url.searchParams.set('publisher', params.publishers.join(','))
            // }
            // if (!!params.operators && params.operators.length > 0) {
            //   url.searchParams.set('operator', params.operators.join(','))
            // }
            // if (!!params.pages && params.pages.length > 0) {
            //   url.searchParams.set('page', params.pages.join(','))
            // }
            // if (!!params.service1s && params.service1s.length > 0) {
            //   url.searchParams.set('service1', params.service1s.join(','))
            // }
            // if (!!params.from) {
            //   url.searchParams.set('from', params.from.toString())
            // }
            history.push(url.pathname + '?' + [...url.searchParams].map(x => x.join('=')).join('&'))
          }}
        />
      }} />
      <Route path="/empello/country-stack/:dimension" exact render={args => {
        const { country, affiliate, from, params, query } = getStandardParams(args)
        const windowSize = paramsToNumber(query, 'window', 7)
        const mode = parseToEnum(['line', 'bar'], query, 'mode', 'line')
        return <EmpelloTicketsCountryStack dimension={params.dimension} country={country} from={from} window={windowSize} affiliate={affiliate} mode={mode} />
      }} />
      <Route path="/revenue-stack/:country/:dimension" exact render={args => {
        const { country, affiliate, publisher, from, params, query, operator, gateway, page, service1 } = getStandardParams(args)
        const windowSize = paramsToNumber(query, 'window', 7)
        const resolution = paramsToNumber(query, 'resolution', 7)
        const mode = parseToEnum(['line', 'bar'], query, 'mode', 'line')
        return <RevenueBreakdownStack legend={{ orientation: 'h' }} dimension={params.dimension} country={country} from={from} window={windowSize} affiliate={affiliate} publisher={publisher} operator={operator} gateway={gateway} page={page} service1={service1} resolution={resolution} mode={mode} />
      }} />
    </Router>
  );
}

export default App;
