const d3 = window['Plotly'].d3

const map = new Map<string, (v: string) => string>()

export default function getCategoryColor(category: string, value:string) {
  var f = map.get(category);
  if(!f) {
    f = d3.scale.category20()
    map.set(category, f)
    // console.log(category, value, f(value))
  }
  // console.log('category', category, value, f(value))
  return f(value);
}