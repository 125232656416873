import React from 'react'

export function useLatestTargets() {
  const [data, set_data] = React.useState([]);
  React.useEffect(() => {
    set_data([])
    fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rQ59i78/versions/2/execute/7200/query`)
      .then(x => x.json())
      .then(x => set_data(x));
  }, []);

  return data
}