import React from 'react';
import useWindowSize from './hooks/window-resize';
import { Plot } from './Plot';
import useEmpelloTicketsCountryStack from './hooks/useEmpelloTicketsCountryStack';
import * as R from 'ramda';
export function EmpelloTicketsCountryStack({ dimension, country, from, window, affiliate, mode }: {
  dimension: string;
  from: number;
  window: number;
  affiliate: null | string;
  country: null | string;
  mode: 'bar' | 'line'
}) {
  const size = useWindowSize();
  const data = useEmpelloTicketsCountryStack({dimension, country, from, window, affiliate})

  const stacks = R.pipe(
    R.groupBy((x: any) => x.country_code)
  , R.toPairs
  , R.map(([country, data]) => ({
      stackgroup: 'one',
      total: R.sum(data.map(x => mode === 'bar' ? x.actual_tickets : x.tickets)),
      x: data.map(x => x.d_month),
    y: data.map(x => Math.ceil(mode === 'bar' ? x.actual_tickets : x.tickets)),
      // text: country,
      // hoverinfo: 'text',
      name: country,
    ...(mode === 'bar' ? { type: 'bar'} : {})
  }))
  , R.sortBy(x => x.total * -1)
  )(data)


  return (<Plot data={stacks} layout={{
    width: size.innerWidth, height: size.innerHeight,
    showlegend: !!true,
    legend: { "orientation": "v", y: 1 },
    margin: {
      t: 30, b: 30, l: 50, r: 20
    },
    xaxis: {
      domain: [0, 1]
    },
    yaxis: {
      title: 'Empello Tickets', anchor: 'x',
      hoverformat: ',.0f',
      tickformat: ',s',
      domain: [0, 1],
    },
    ...(mode === 'bar' ? {
      bargap: 0,
      barmode: 'stack'} : {})
  }} />);
}
