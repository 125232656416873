import React from 'react';
import { d3 } from './Plot';
import "./DimensionSummaryTable.scss"
import { useLandingPagesCR_WithFilters } from './hooks/useLandingPagesCRTable';

export function LandingPagesCR({ from, country, gateway, affiliate, publisher, operator, service1 }: {
  from: number;
  country: string;
  gateway: null | string;
  affiliate: null | string;
  publisher: null | string;
  operator: null | string;
  service1: null | string;
}) {
  const data = useLandingPagesCR_WithFilters({ country, from, gateway, affiliate, publisher, operator, service1 })
  return <PlotLandingPagesCR data={data} />
}

export function PlotLandingPagesCR({ data }) {
  return (<div className='data-table-container'>
    <table className='data-table'>
      <thead>
        <tr>
          <th style={{width: '1em'}}></th>
          <th style={{ width: '40%' }}></th>
          <th>Views</th>
          <th>Sales</th>
          <th>CR</th>
          <th>CQ</th>
          <th>W1</th>
          <th>eCPA</th>
        </tr>
      </thead>
      <tbody>
        {data.map(r => {
          const url = (() => {
            if (!r.host || !r.xcid_or_country) {
              return null
            }
            const host = r.host.split(',')[0]
            const xcid_or_country = r.xcid_or_country.split(',')[0]
            return r.is_ouisys === 'Ouisys'
              ? `http://${host}/${xcid_or_country}`
              : `http://${host}/${xcid_or_country}/${r.page}?offer=1`
          })()
          return <tr key={`${r.page}-${r.host}-${r.xcid_or_country}`}>
            <td style={{ borderRight: 0 }}>{r.is_ouisys === 'Ouisys' ? '🏝' : 'ℬ'}</td>
            <td className='label' style={{ borderLeft: 0 }}><a href={url} target="_blank" rel="noopener noreferrer">{r.page}</a></td>
            <td>{d3.format(',')(r.views)}</td>
            <td>{d3.format(',')(r.sales)}</td>
            <td>{d3.format('0.2%')(r.cr)}</td>
            <td>{d3.format('%')(r.cq)}</td>
            <td>{d3.format('0.2f')(r.week_1_arpu_by_avg)}</td>
            <td>{d3.format('0.2f')(r.ecpa)}</td>
          </tr>
        })}
      </tbody>
    </table>
  </div>);
}