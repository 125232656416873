import React from 'react';
import { Plotly } from './Plot';
export function Plot(traces: Array<any>, layout: any, options: any, handler: (div: any) => void) {
  const divRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if(traces.length > 0) {
        Plotly.newPlot(divRef.current, traces, layout, options);
        handler(divRef.current);
    }
  }, [traces, layout, options, handler]);
  return <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} ref={divRef}></div>;
}
export {Plotly}
