import * as React from 'react';
import { Range, getTrackBackground } from 'react-range';

const STEP = 10;

class Slider extends React.PureComponent<{ min: number, max: number, value: number, onChange: (value: number) => void }> {
  state = {
    values: [this.props.value]
  };
  timer = null
  setValue(value: number) {
    this.setState({ values: [value] });
    if (!!this.timer)
      clearTimeout(this.timer)

    this.timer = setTimeout(() => {
      this.props.onChange(value)
    }, 1000);
  }
  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          // flexWrap: 'wrap',
        }}
      >
        <Range
          values={this.state.values}
          step={STEP}
          min={this.props.min}
          max={this.props.max}
          onChange={values => this.setValue(values[0])}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%'
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '5px',
                  width: '100%',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values: this.state.values,
                    colors: ['#548BF4', '#ccc'],
                    min: this.props.min,
                    max: this.props.max
                  }),
                  alignSelf: 'center'
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '18px',
                width: '12px',
                borderRadius: '4px',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 2px 6px #AAA'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-10px',
                  color: '#000',
                  fontWeight: 'bold',
                  fontSize: '10px',
                  fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                  padding: '0px',
                  borderRadius: '0px',
                  // backgroundColor: '#548BF4'
                }}
              >
                {this.state.values[0].toFixed(1)}
              </div>
              <div
                style={{
                  height: '16px',
                  width: '5px',
                  backgroundColor: isDragged ? '#548BF4' : '#CCC'
                }}
              />
            </div>
          )}
        />
      </div>
    );
  }
}

export default Slider;