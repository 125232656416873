import React, { useRef } from 'react';
import * as R from 'ramda'
import { d3 } from './Plot';
import {useCountryRevenueCostECPA_WithFilters} from './hooks/useCountryRevenueCostECPA';
import "./CountryTable.scss"
import { PlotSalesTargets } from './SalesTargets';
import { useLatestTargets } from './hooks/useLatestTargets';

const change = (a: any, b: any) => b === 0 ? NaN : a / b - 1
const metric = lens => (n: any, b: any) => {
  const value = R.view(lens)(n)
  const bValue = R.view(lens)(b)
  return {
    value,
    change: change(value, bValue)
  }
}

export const stats = R.pipe(
  R.filter((d: any) => d.rnk % 7 === 0)
  , R.map(x =>
    ({ 
      ...R.set(R.lensProp('avg_ecpa'))(x.avg_cost / x.avg_sales, x), 
      ...R.set(R.lensProp('avg_dmb_ecpa'))(x.avg_dmb_cost / x.avg_dmb_sales, x), 
      ...R.set(R.lensProp('avg_aff_ecpa'))(x.avg_aff_cost / x.avg_aff_sales, x), 
      ...R.set(R.lensProp('avg_chain_ecpa'))(x.avg_chain_cost / x.avg_chain_sales, x), 
      ...R.set(R.lensProp('avg_pixel_rate'))(x.avg_pixels / x.avg_sales, x), 
      ...R.set(R.lensProp('date'))(new Date(x.day), x) 
    })
  )
  , x => R.zip(x, R.drop(1)(x))
  , R.map(([n, b]) => ({
    date: n.date
    , sales: metric(R.lensProp('avg_sales'))(n, b)
    , dmb_sales: metric(R.lensProp('avg_dmb_sales'))(n, b)
    , aff_sales: metric(R.lensProp('avg_aff_sales'))(n, b)
    , chain_sales: metric(R.lensProp('avg_chain_sales'))(n, b)
    , pixels: metric(R.lensProp('avg_pixels'))(n, b)
    , pixel_rate: metric(R.lensProp('avg_pixel_rate'))(n, b)
    , cost: metric(R.lensProp('avg_cost'))(n, b)
    , dmb_cost: metric(R.lensProp('avg_dmb_cost'))(n, b)
    , aff_cost: metric(R.lensProp('avg_aff_cost'))(n, b)
    , chain_cost: metric(R.lensProp('avg_chain_cost'))(n, b)
    , revenue: metric(R.lensProp('avg_revenue'))(n, b)
    , ecpa: metric(R.lensProp('avg_ecpa'))(n, b)
    , dmb_ecpa: metric(R.lensProp('avg_dmb_ecpa'))(n, b)
    , aff_ecpa: metric(R.lensProp('avg_aff_ecpa'))(n, b)
    , chain_ecpa: metric(R.lensProp('avg_chain_ecpa'))(n, b)
    , cq: metric(R.lensProp('avg_cq'))(n, b)
  }))
)

export function CountryTable({ country, gateway, affiliate, publisher, operator }: {
  country: string;
  gateway: null | string;
  affiliate: null | string;
  publisher: null | string;
  operator: null | string;
}) {
  React.useEffect(() => {
    document.body.classList.add("no-scroll")
    return () => document.body.classList.remove("no-scroll")
  }, [])
  const data = stats(useCountryRevenueCostECPA_WithFilters({ country, from: -154, cost_window: 7, revenue_window: 31, sales_from: null, sales_to: null, gateway, affiliate, publisher, operator, page: null, service1: null }))
  const dataForTargets = stats(useCountryRevenueCostECPA_WithFilters({ country, from: -35, cost_window: 31, revenue_window: 31, sales_from: null, sales_to: null, gateway, affiliate, publisher, operator, page: null, service1: null }))
  const salesTargets = useLatestTargets();
  return <PlotCountryTableSummary data={data} dataForTargets={dataForTargets} targets={salesTargets.find(t => t.country_code === country)} />
}

export function PlotCountryTable({ data }) {
  return (<div>
    {
      data.map((row, i) =>
        <div key={i.toString()} className="row">
          <div className="date">
            {d3.time.format("%a %d %b")(row.date)} - USD {d3.format(",.0f")(row.cost.value as number * 7)}
          </div>
          <div className='metrics'>
            {
              ['sales', 'ecpa', 'cost', 'revenue'].map(k =>
                <div key={k} className="metric">
                  <div className='label'>{k === 'ecpa' ? 'e' : k.slice(0, 1).toUpperCase()}</div>
                  <div className='value'>{d3.format(k === 'ecpa' ? ",.2f" : ",.0f")(row[k].value)}</div>
                  <div className={`change ${row[k].change < 0 ? 'n' : 'p'}`}>{isNaN(row[k].change) || Math.abs(row[k].change) > 10 ? '' : d3.format("%")(row[k].change)}</div>
                </div>
              )
            }
          </div>
        </div>
      )
    }
  </div>);
}

function changeCSS(m) {
  return { '--change': `'${isNaN(m.change) || Math.abs(m.change) > 10 ? '' : d3.format('%')(Math.abs(m.change))}'`, '--color': m.change > 0 ? `#2196f3b5` : `#ff5722b5` } as any
}

export function PlotCountryTableSummary({ data, targets, dataForTargets }: { data: any[], dataForTargets?: any[], targets?: any[]}) {
  const tableRef  = useRef<HTMLTableElement>()
  const onMouseEnter = e => {
      const t = e.target as HTMLTableRowElement
      tableRef.current.dataset.selectedCol = t.dataset.col;
    }
  const onMouseOut = e => {
      const t = e.target as HTMLTableRowElement
      tableRef.current.dataset.selectedCol = ""
    }
  return (<div className='data-table-container'>
    {
      !!targets && !!dataForTargets && dataForTargets.length > 0 ? <div style={{ marginBottom: '1em' }}><PlotSalesTargets period={31} data={R.head(dataForTargets)} targets={targets} /></div> : ''
    }
    <table ref={tableRef} className='data-table enlarge-first'>
      <thead>
        <tr>
          <th data-col="1" style={{ width: '20%' }}></th>
          <th data-col="2">Aff Sales</th>
          <th data-col="3">Aff eCPA</th>
          <th data-col="4">DMB Sales</th>
          <th data-col="5">DMB eCPA</th>
          <th data-col="6">+ Sales</th>
          <th data-col="7">eCPA</th>
          <th data-col="8">Pixels</th>
          <th data-col="9">CQ</th>
          <th data-col="10">Cost</th>
          <th data-col="11">Revenue</th>
        </tr>
      </thead>
      <tbody>
        {
        data.map((r, i) => {
          return <tr data-row={i} key={`${r.date.toString()}`}>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="1" className='label'>{d3.time.format("%a %d %b")(r.date)}</td>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="2" className='change' style={changeCSS(r.aff_sales)}>{d3.format(',.0f')(r.aff_sales.value)}</td>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="3" className='change' style={changeCSS(r.aff_ecpa)}>{isNaN(r.aff_ecpa.value) ? '-' : d3.format('0.2f')(r.aff_ecpa.value)}</td>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="4" className='change' style={changeCSS(r.dmb_sales)}>{isNaN(r.dmb_sales.value) ? '-' : d3.format(',.0f')(r.dmb_sales.value)}</td>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="5" className='change' style={changeCSS(r.dmb_ecpa)}>{isNaN(r.dmb_ecpa.value) ? '-' : d3.format('0.2f')(r.dmb_ecpa.value)}</td>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="6" className='change' style={changeCSS(r.chain_sales)}>{isNaN(r.chain_sales.value) ? '-' : d3.format(',.0f')(r.chain_sales.value)}</td>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="7" className='change' style={changeCSS(r.ecpa)}>{isNaN(r.ecpa.value) ? '-' : d3.format('0.2f')(r.ecpa.value)}</td>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="8">{isNaN(r.pixel_rate.value) ? '-' : d3.format('%')(r.pixel_rate.value)}</td>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="9">{d3.format('%')(r.cq.value)}</td>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="10" className='change' style={changeCSS(r.cost)}>{Math.round(r.cost.value) === 0 ? 0 : d3.format(',.3s')(r.cost.value)}</td>
            <td onMouseEnter={onMouseEnter} onMouseOut={onMouseOut} data-col="11" className='change' style={changeCSS(r.revenue)}>{d3.format(',.3s')(r.revenue.value)}</td>
          </tr>
        })
        }
      </tbody>
    </table>
  </div>);
}
