import React from 'react'
import * as R from 'ramda';
import enqueue from './_fetchQueue'

export type IProps = {
  country: string;
  from: number;
  window: number;
  gateway: null | string;
  affiliate: null | string;
  operator: null | string;
}

export type IPropsWithFilters = IProps & {
  page: null | string;
  service1: null | string;
  publisher: null | string;
}

export function useCountryARPU_Classic({
  country,
  from,
  window,
  gateway,
  affiliate,
  operator
} : IProps) {
  const [data, set_data] = React.useState([]);
  React.useEffect(() => {
    fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rmTsmcQ/versions/20/execute/7200/query?from=${from}&window=${window}&country_code=${country}&gateway=${!gateway ? '-' : gateway}&affiliate=${!affiliate ? '-' : affiliate}&operator=${!operator ? '-' : operator}&section=%27-%27`)
      .then(x => x.json())
      .then(R.pipe(
        R.map((x: any) =>
          ({ ...R.set(R.lensProp('date'))(new Date(x.row), x) })
        )
      ))
      .then(x => set_data(x));
  }, [
    country,
    from,
    window,
    gateway,
    affiliate,
    operator
  ]);

  return data
}

export function useCountryARPU_WithFilters({
  country,
  from,
  window,
  gateway,
  affiliate,
  publisher,
  operator,
  page,
  service1
}: IPropsWithFilters) {
  const [data, set_data] = React.useState([]);
  React.useEffect(() => {
    set_data([])
    enqueue(() => fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rE0UGT1/versions/8/execute/7200/query?from=${from}&window=${window}&countries=${country}&gateways=${!gateway ? '-' : gateway}&affiliates=${!affiliate ? '-' : affiliate}&publishers=${publisher || '-'}&operators=${!operator ? '-' : operator}&pages=${!page ? '-' : page}&service1=${!service1 ? '-' : service1}`)
      .then(x => x.json())
      .then(R.pipe(
        R.map((x: any) =>
          ({ ...R.set(R.lensProp('date'))(new Date(x.row), x) })
        )
      ))
      .then(x => set_data(x)));
  }, [
    country,
    from,
    window,
    gateway,
    affiliate,
    publisher,
    operator,
    page,
    service1
  ]);

  return data
}