import React from 'react'

export type IProps = {
  country: string;
  from: number;
  cost_window: number;
  revenue_window: number;
  sales_from: number | null;
  sales_to: number | null;
  gateway: null | string;
  affiliate: null | string;
  operator: null | string;
}

export type IPropsWithFilters = IProps & {
  page: null | string;
  service1: null | string;
  publisher: null | string;
}

export function useCountryRevenueCostECPA_Classic({
  country,
  from,
  cost_window,
  revenue_window,
  sales_from,
  sales_to,
  gateway,
  affiliate,
  operator
}: IProps) {
  const [data, set_data] = React.useState([]);
  React.useEffect(() => {
    fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rsk7lkS/versions/23/execute/7200/query?from=${from}&countries=%27${country}%27&gateway=${gateway || '-'}&operator=${operator || '-'}&affiliate=${affiliate || '-'}&cost_window=${cost_window}&revenue_window=${revenue_window}&sales_from=${sales_from || 0}&sales_to=${sales_to || 0}`)
      .then(x => x.json())
      .then(x => set_data(x));
  }, [
    country,
    from,
    cost_window,
    revenue_window,
    sales_from,
    sales_to,
    gateway,
    affiliate,
    operator
  ]);

  return data
}

export function useCountryRevenueCostECPA_WithFilters({
  country,
  from,
  cost_window,
  revenue_window,
  sales_from,
  sales_to,
  gateway,
  affiliate,
  publisher,
  operator,
  page,
  service1
}: IPropsWithFilters) {
  const [data, set_data] = React.useState([]);
  React.useEffect(() => {
    set_data([])
    fetch(`https://pipe.sam-media.com/apis/projects/575422b0156bf736d3b6fb59/documents/rDVCOuP/versions/26/execute/7200/query?from=${from}&countries=${country || '-'}&gateways=${gateway || '-'}&operators=${operator || '-'}&affiliates=${affiliate || '-'}&publishers=${publisher || '-'}&pages=${page || '-'}&cost_window=${cost_window}&revenue_window=${revenue_window}&sales_from=${sales_from || 0}&sales_to=${sales_to || 0}&service1=${service1 || '-'}`)
      .then(x => x.json())
      .then(x => set_data(x));
  }, [
    country,
    from,
    cost_window,
    revenue_window,
    sales_from,
    sales_to,
    gateway,
    affiliate,
    publisher,
    operator,
    page,
    service1
  ]);

  return data
}