import PQueue from "p-queue"
const queue = new PQueue({ concurrency: 2 });



export default function enqueue(f: () => Promise<any>) {
  queue.add(f)
  .then(() => 
    window.dispatchEvent(new CustomEvent('queue-size-changed', { detail: { size: queue.size, event: 'completed' } }))
  ).catch(() => 
    window.dispatchEvent(new CustomEvent('queue-size-changed', { detail: { size: queue.size, event: 'completed-with-error' } }))
  )
  window.dispatchEvent(new CustomEvent('queue-size-changed', { detail: { size: queue.size, event: 'added' } }))
}