import React from 'react';
import { d3 } from './Plot';
import "./DimensionSummaryTable.scss"
import { useOuisysCampaigns_WithFilters } from './hooks/useOuisysCampaigns';

export function OuisysCampaignsSummaryTable({ page, from, country, gateway, affiliate, publisher, operator, service1 }: {
  from: number;
  country: string;
  gateway: null | string;
  affiliate: null | string;
  publisher: null | string;
  operator: null | string;
  page: null | string;
  service1: null | string;
}) {
  const data = useOuisysCampaigns_WithFilters({ page, country, from, gateway, affiliate, publisher, operator, service1 })
  return <PlotLandingPagesCR data={data} />
}

export function PlotLandingPagesCR({ data }) {
  return (<div className='data-table-container'>
    <table className='data-table'>
      <thead>
        <tr>
          <th style={{ width: '25%' }}></th>
          <th>Affiliate</th>
          <th>Page</th>
          <th>Views</th>
          <th>Sales</th>
          <th>Engagement</th>
          <th>CR</th>
          <th>CQ</th>
          <th>Pixels</th>
          <th>eCPA</th>
          <th>W1</th>
        </tr>
      </thead>
      <tbody>
        {data.map(r => {
        const [_os, h_country, h_page, h_scenario_stuff] = r.handle_name.split(':')
        const [strategy, scenarios_config] = h_scenario_stuff.split('_')
        const campaign_clone_link = `https://panel.ouisys.com/campaigns/create?country=${r.country}&page=${h_page}` + (
          !!scenarios_config 
          ? `&strategy=${strategy}&scenarios_config=${scenarios_config}` 
          : `&scenario=${h_scenario_stuff}`
        )
        const _preview_url = `https://s3.eu-central-1.amazonaws.com/mobirun/os-ui/static/${h_page}/html/${h_country}-${h_scenario_stuff}-production.html`
        const campaign_url = `https://c1.ouisys.com/${r.xcid}`
          return <tr key={`${r.xcid}`}>
            <td className='label'>{r.comments} <small>({r.xcid})</small></td>
            <td>{r.affiliate_name} <small>({r.affiliate_id})</small></td>
            <td>
              <a title="Clone this Campaign" style={{textDecoration: 'none'}} target="_blank" rel="noopener" href={campaign_clone_link}>📑</a>
              &nbsp;
              <a target="_blank" rel="noopener" href={campaign_url}>{h_page}:<small>{h_scenario_stuff}</small></a>
             
            </td>
            <td>{d3.format(',')(r.visits)}</td>
            <td>{d3.format(',')(r.sales)}</td>
            <td>{d3.format('0.2%')(r.engagement_rate)}</td>
            <td>{d3.format('0.2%')(r.cr)}</td>
            <td>{d3.format('%')(r.cq)}</td>
            <td>{d3.format('%')(r.pixel_firing_ratio)}</td>
            <td>{d3.format('0.2f')(r.ecpa)}</td>
            <td>{d3.format('0.2f')(r.arpu_7)}</td>
          </tr>
        })}
      </tbody>
    </table>
  </div>);
}