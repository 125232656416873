import React from 'react';
import { Plot, d3 } from './Plot';
import {useCountryRevenueCostECPA_WithFilters} from './hooks/useCountryRevenueCostECPA';

export function CountryTimeSeries({ from, cost_window, revenue_window, country, gateway, affiliate, publisher, operator, page, service1 }: {
  from: number;
  cost_window: number;
  revenue_window: number;
  country: string;
  gateway: null | string;
  affiliate: null | string;
  publisher: null | string;
  operator: null | string;
  page: null | string;
  service1: null | string;
}) {
  const data = useCountryRevenueCostECPA_WithFilters({ country, from, cost_window, revenue_window, sales_from: null, sales_to: null, gateway, affiliate, publisher, operator, page, service1 })
  return <PlotCountryTimeSeries data={data} />
}

export function PlotCountryTimeSeries({ data }) {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
      <Plot
        useResizeHandler={true}
        style={{ width: "100%", height: "100%" }}
        data={[
          {
            type: "bar",
            yaxis: 'y1',
            x: data.map(x => x.day),
            y: data.map(x => x.avg_dmb_sales),
            name: 'Avg DMB Sales',
            marker: {
              color: '#e2e2e255'
            },
          },
          {
            type: "bar",
            yaxis: 'y1',
            x: data.map(x => x.day),
            y: data.map(x => x.avg_sales - (x.avg_dmb_sales || 0)),
            name: 'Avg Sales',
            marker: {
              color: '#e2e2e2aa'
            },
          },
          {
            x: data.map(x => x.day),
            y: data.map(x => x.avg_cost),
            yaxis: 'y2',
            xaxis: 'x',
            type: "scatter",
            mode: "lines+points",
            marker: { color: "#ff7f0e" },
            name: 'Avg Cost',
          },
          {
            x: data.map(x => x.day),
            y: data.map(x => x.avg_dmb_cost),
            yaxis: 'y2',
            xaxis: 'x',
            type: "scatter",
            mode: "lines+points",
            marker: { color: "#ff7f0e66" },
            name: 'Avg DMB Cost',
            line: {
              dash: 'dash'
            }
          },
          {
            x: data.map(x => x.day),
            y: data.map(x => x.revenue),
            yaxis: 'y2',
            xaxis: 'x',
            type: "scatter",
            mode: "lines+points",
            marker: {
              color: "#a6cee3AA"
            },
            name: 'Revenue'
          },
          {
            x: data.map(x => x.day),
            y: data.map(x => x.avg_revenue),
            yaxis: 'y2',
            xaxis: 'x',
            type: "scatter",
            mode: "lines+points",
            marker: { color: "#1f77b4" },
            name: 'Avg Revenue'
          },
          {
            x: data.map(x => x.day),
            y: data.map(x => x.avg_dmb_revenue),
            yaxis: 'y2',
            xaxis: 'x',
            type: "scatter",
            mode: "lines+points",
            marker: { color: "#1f77b466" },
            name: 'Avg DMB Revenue',
            line: {
              dash: 'dash'
            }
          },
          {
            x: data.map(x => x.day),
            y: data.map(x => x.avg_sales === 0 ? NaN : x.avg_cost / x.avg_sales),
            yaxis: 'y3',
            xaxis: 'x',
            type: "scatter",
            mode: "lines+points",
            marker: { color: "#fbb4ae" },
            name: 'Avg eCPA',
            line: {
              dash: 'dashdot',
              width: 2
            }
          },
          {
            x: data.map(x => x.day),
            y: data.map(x => x.avg_dmb_sales === 0 ? NaN : x.avg_dmb_cost / x.avg_dmb_sales),
            yaxis: 'y3',
            xaxis: 'x',
            type: "scatter",
            mode: "lines+points",
            marker: { color: "#f0d400" },
            name: 'Avg DMB eCPA',
            line: {
              dash: 'dashdot',
              width: 2
            }
          },
        ]} layout={{
          // modeBarButtonsToRemove: ['sendDataToCloud', 'hoverCompareCartesian'],
          barmode: 'stack',
          // width: size.innerWidth, height: size.innerHeight,
          showlegend: true,
          legend: { "orientation": "h" },
          margin: {
            t: 0, b: 0, l: 50, r: 20
          },
          xaxis: {
            domain: [0, 0.95]
          },
          yaxis: {
            title: 'Sales', anchor: 'x',
            hoverformat: ',.0f',
            tickformat: ',s',
            domain: [0, 1],
          },
          yaxis2: {
            title: 'USD',
            titlefont: { color: 'rgb(148, 103, 189)' },
            tickfont: { color: '#9467bd' },
            anchor: 'free',
            position: 0.95,
            overlaying: 'y',
            side: 'right',
            showgrid: false,
            hoverformat: ',.0f',
            tickformat: ',s',
            domain: [0, 1],
            rangemode: 'tozero',
            zeroline: false,
            range: [0, d3.max([d3.max(data, e => e.cost), d3.max(data, e => e.revenue)]) * 1.05]
          },
          yaxis3: {
            title: '',
            titlefont: {
              color: '#fbb4ae'
            },
            tickfont: { color: '#fbb4ae' },
            anchor: 'free',
            overlaying: 'y',
            side: 'right',
            position: 1,
            showgrid: false,
            hoverformat: ',.2f',
            rangemode: 'tozero',
            zeroline: false,
            range: [0, d3.max(data, x => x.avg_sales === 0 ? NaN : x.avg_cost / x.avg_sales) * 1.05]
          },
          autosize: true,
        }} />
    </div>);
}